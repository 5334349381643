import { Box } from "@mui/material";
import { aither, gaimin, rogue_engine } from "../../../images/landing";
import { HeaderTag } from "../../ui-elements/HeaderTag";
import { Spacer } from "../../ui-elements/layout-elements/Spacer";
import { PartnerCell } from "./cells/PartnerCell";
import { DotsLocation } from "../../../types/ui/dotsTypes";
import { Dots } from "../../ui-elements/Dots";

const PartnerSection: React.FC = () => {
  return (
    <Box
      position={"relative"}
      top={0}
      bottom={0}
      left={0}
      right={0}
      justifyContent={"center"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      paddingTop={"64px"}
      paddingBottom={{ xs: "32px", sm: "64px" }}
    >
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        maxWidth={"1200px"}
        paddingLeft={"24px"}
        paddingRight={"24px"}
      >
        <HeaderTag text={"Partner Network"} />
        <Spacer height={"24px"} />
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          <PartnerCell image={gaimin} />
          <Spacer width={"32px"} height={"32px"} />
          <PartnerCell image={rogue_engine} />
          <Spacer width={"32px"} height={"32px"} />
          <PartnerCell image={aither} />
        </Box>
        <Spacer height={"32px"} />
      </Box>
      <Dots dotsLocation={DotsLocation.left} />
    </Box>
  );
};

export default PartnerSection;
