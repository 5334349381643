import React, { useState, useEffect } from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { newsletterFormConfig } from "./newsletterFormConfig";
import { submitForm } from "../../../features/forms/formSubmissionSlice";
import { FormData, FormType } from "../../../types/forms";
import {
  SubmitButton,
  FormSection,
  InputField,
  ErrorMessage,
} from "../FormComponents";
import FormSubmissionFeedback from "../FormSubmissionFeedback";

interface NewsletterFormProps {
  initialEmail?: string;
}

const NewsletterForm: React.FC<NewsletterFormProps> = ({
  initialEmail = "",
}) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormData>({
    submission_type: "newsletter",
  });
  const formState = useAppSelector(
    (state) => state.formSubmission[FormType.NEWSLETTER]
  );

  useEffect(() => {
    if (initialEmail) {
      setFormData((prevData) => ({
        ...prevData,
        email: initialEmail,
      }));
    }
  }, [initialEmail]);

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const target = e.target as
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement;
    const name = target.name;
    const value =
      target.type === "checkbox"
        ? (target as HTMLInputElement).checked
        : target.value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(submitForm({ formType: FormType.NEWSLETTER, data: formData }));
  };

  if (formState?.isSubmitted) {
    return (
      <FormSubmissionFeedback
        title="Thank you for signing up!"
        message="You have successfully subscribed to our newsletter."
        center
      />
    );
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: "100%",
        maxWidth: "600px",
        margin: "auto",
        fontFamily: '"Be Vietnam Pro", sans-serif',
      }}
    >
      <FormSection title={newsletterFormConfig.title}>
        {newsletterFormConfig.fields.map((field) =>
          field.type !== "hidden" ? (
            <InputField
              key={field.name}
              field={field}
              value={formData[field.name] || ""}
              onChange={handleInputChange}
            />
          ) : null
        )}
      </FormSection>
      <SubmitButton
        text={newsletterFormConfig.submitButtonText}
        isProcessing={formState?.isProcessing || false}
      />

      {formState?.error && <ErrorMessage message={formState.error} />}
    </Box>
  );
};

export default NewsletterForm;
