import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import PopupBase from "../PopupBase";
import { Close } from "../../../icons";
import NewsletterForm from "../../forms/newsletter/NewsletterForm";

interface NewsletterPopupProps {
  triggerPopup?: boolean;
  onConfirm?: () => void;
  onDismiss?: () => void;
  initialEmail?: string;
}

const NewsletterPopup: React.FC<NewsletterPopupProps> = ({
  triggerPopup = false,
  onConfirm,
  onDismiss,
  initialEmail = "",
}) => {
  const [isOpen, setIsOpen] = useState(triggerPopup);

  useEffect(() => {
    setIsOpen(triggerPopup);
  }, [triggerPopup]);

  const handleClose = () => {
    setIsOpen(false);
    if (onDismiss) {
      onDismiss();
    }
  };

  function handleKeyPress(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      handleClose();
    }
  }

  return isOpen ? (
    <PopupBase open={isOpen} onClose={() => console.log("")}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
        height="100%"
      >
        <IconButton
          onClick={handleClose}
          onKeyDown={handleKeyPress}
          sx={{
            height: "32px",
            width: "32px",
            padding: "4px",
            marginRight: "4px",
            alignSelf: "flex-end",
          }}
        >
          <Close />
        </IconButton>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          height={"100%"}
          sx={{
            padding: "30px",
            borderRadius: "8px",
            margin: "auto",
          }}
        >
          <NewsletterForm initialEmail={initialEmail} />
        </Box>
      </Box>
    </PopupBase>
  ) : null;
};

export default NewsletterPopup;
