import { alpha, Box, Grid, useTheme } from "@mui/material";
import { Spacer } from "../../../../ui-elements/layout-elements/Spacer";
import { Header3 } from "../../../../ui-elements/typography/Header3";
import { Body } from "../../../../ui-elements/typography/Body";
import React from "react";
import { TextLink } from "../../../../ui-elements/TextLink";
import { IProgram } from "../../../../../types/data/IProgram";

interface ProgramCellProps {
  program: IProgram;
}

export const ProgramCell: React.FC<ProgramCellProps> = ({ program }) => {
  const theme = useTheme();
  const textAlign = "center";

  const processClick = (program) => {
    window.location.href = program.url;
  };

  return (
    <Grid item>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        paddingRight={"8px"}
        paddingLeft={"8px"}
        maxWidth={"420px"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          sx={{
            fill: theme.palette.text.primary,
          }}
        >
          {program.icon}
        </Box>
        <Spacer height={"16px"} />
        <Header3 text={program.pageTitle} textAlign={textAlign} />
        <Spacer height={"16px"} />
        <Body
          text={program.shortDescription}
          textAlign={textAlign}
          sx={{ opacity: "0.5" }}
        />
        <TextLink
          text={"Learn More"}
          onClick={() => processClick(program)}
          sx={{
            width: "120px",
            textAlign: textAlign,
            fontWeight: 900,
            marginTop: "16px",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "4px",
            paddingRight: "4px",
            "&:hover": {
              borderRadius: "8px",
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              cursor: "pointer",
            },
          }}
        />
      </Box>
    </Grid>
  );
};
