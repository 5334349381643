import { Box, lighten, useTheme } from "@mui/material";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { Body } from "../../../ui-elements/typography/Body";
import { Header4 } from "../../../ui-elements/typography/Header4";

export const ContactsIntroSection = () => {
  const theme = useTheme();
  const textColor = theme.palette.colors.blue[900];

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignSelf={"center"}
      width={"100%"}
      height={"100%"}
      maxWidth={"1000px"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        padding={{ xs: "24px", sm: "48px" }}
      >
        <Spacer height={"24px"} />
        <Header4 text={"How Can We Assist You?"} textAlign={"left"} />
        <Spacer height={"12px"} />
        <Body
          text={`Please provide your details and the reason for your inquiry below. We look forward to collaborating and supporting your journey with the Multisynq ecosystem.`}
          textAlign={"left"}
          sx={{
            color: lighten(textColor, 0.4),
          }}
        />
      </Box>
    </Box>
  );
};
