import event_foundation from "../../../../../images/landing/events/event-foundation.jpeg";
import event_rogue from "../../../../../images/landing/events/event-rogue.jpeg";
import event_gaimin from "../../../../../images/landing/events/event-gaimin.jpeg";

export interface IEvent {
  handle: string;
  date: string;
  text: string;
  image?: string;
  url?: string;
}

export const events: IEvent[] = [
  {
    handle: "@multisynq",
    date: "6-7-24",
    text: `We're thrilled to announce a game changer partnership 👀

Multisynq 🤝 
@GaiminIo



Together, we're integrating our Synchronizers into their powerful decentralized GPU network, bringing real-time, low-latency multiplayer experiences to the next level.

Time to Synq 🔀`,
    image: event_gaimin,
    url: "https://x.com/multisynq/status/1806347046707933402",
  },
  {
    handle: "@multisynq",
    date: "7-2-24",
    text: `Proud to announce our partnership with Rogue Engine and the release of Rogue Croquet 🔀

Rogue Engine, a Unity-like environment for creating web apps and games with 
@threejs
 has developed Rogue Croquet using the Multisynq Network for seamless serverless networking

Take a look 👇`,
    image: event_rogue,
    url: "https://x.com/multisynq/status/1808270939920191664",
  },
  {
    handle: "@multisynq",
    date: "7-25-24",
    text: `Introducing the Multisynq Foundation 🌟

A major step in decentralizing our network and fostering innovation in collaborative games and applications.

Stay tuned for more details, programs and announcements to come!

⏩ Learn More:`,
    image: event_foundation,
    url: "https://x.com/multisynq/status/1816526933733310898",
  },
];
