import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  ISuccessStory,
  nameForSuccessStory,
} from "../../types/data/ISuccesStory";
import { successStoryData } from "../pages/success-stories/successStoryData";

export const useLocationToGetSuccessStory = () => {
  const location = useLocation();
  const [activeSuccessStory, setActiveSuccessStory] =
    useState<ISuccessStory | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const storyNameFromParams = queryParams.get("story");
    const storyName = nameForSuccessStory[storyNameFromParams || ""];
    if (storyName) {
      console.log(storyName);

      const story = successStoryData.find((story) => story.name === storyName);
      if (story) {
        setActiveSuccessStory(story);
      }
    }
  }, [location.search]);

  return activeSuccessStory;
};
