import React from "react";
import { Card, Modal, Backdrop, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface PopupBaseProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  entered?: boolean;
}

const PopupBase: React.FC<PopupBaseProps> = ({
  children,
  open,
  onClose,
  entered,
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  const getMargin = () => {
    if (isXs || isSmall) {
      return 16;
    } else if (isMd) {
      return 24;
    } else if (isLg) {
      return 32;
    } else {
      return 48;
    }
  };

  const margin = getMargin();

  return (
    <Modal
      open={open}
      onClose={onClose}
      components={{
        Backdrop: Backdrop,
      }}
      componentsProps={{
        backdrop: {
          sx: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            backdropFilter: "blur(5px)",
            zIndex: 0,
          },
        },
      }}
    >
      <Card
        sx={{
          position: "absolute",
          backgroundColor: "white",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflowY: "auto",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          maxHeight: `min(600px, calc(100% - ${margin * 2}px))`,
          maxWidth: `min(500px, calc(100% - ${margin * 2}px))`,
          width: "auto",
          height: "auto",
          minWidth: { xs: "calc(100% - 24px)", sm: 280 },
          borderRadius: "16px",
          margin: 0,
          padding: theme.spacing(2),
        }}
      >
        {children}
      </Card>
    </Modal>
  );
};

export default PopupBase;
