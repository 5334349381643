import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  events,
  IEvent,
} from "../../components/pages/landing/sections/events-section/eventData";

interface PageManagerState {
  allData: IEvent[];
  filters: string;
  filteredData: IEvent[];
  selectedCell: IEvent | null;
  currentPage: number;
  totalPages: number;
  scrollPosition: number;
  isAnimatingScroll: boolean;
  sessionId: string;
}

const initialState: PageManagerState = {
  allData: events,
  filters: "Show All",
  filteredData: events,
  selectedCell: events[0],
  currentPage: 1,
  totalPages: 1,
  scrollPosition: 0,
  isAnimatingScroll: false,
  sessionId: "",
};

export const fetchData = createAsyncThunk("pageManager/fetchData", async () => {
  // Simulating an API call
  return events;
});

const pageManagerSlice = createSlice({
  name: "pageManager",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.filteredData = state.allData;
    },
    setFilteredExamples: (state, action) => {
      state.filteredData = action.payload;
    },
    setSelectedExample: (state, action) => {
      state.selectedCell = action.payload;
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setisAnimatingScroll: (state, action) => {
      state.isAnimatingScroll = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.allData = action.payload;
      state.filteredData = action.payload;
    });
  },
});

export const {
  setFilters,
  setFilteredExamples,
  setSelectedExample,
  setSessionId,
  setCurrentPage,
  setScrollPosition,
  setTotalPages,
  setisAnimatingScroll,
} = pageManagerSlice.actions;

export default pageManagerSlice.reducer;
