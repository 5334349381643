export function customLinkTheme(theme) {
  return {
    color: theme.palette.colors.grayScale.white,
    fontSize: "12px",
    "&:hover": {
      opacity: 0.8,
      textDecoration: "none",
      cursor: "pointer",
    },
    display: "block",
  };
}
