import EmailService from "../../../../services/EmailService";

export function processInternalLinkClicked(dispatch, navigate) {
  return (path: string) => {
    switch (path) {
      case "/questions":
        EmailService.askQuestion();
        break;
      case "/bug-report":
        EmailService.reportBug();
        break;
      case "/feedback":
        EmailService.sendFeedback();
        break;
      default:
        navigate(path);
        break;
    }
  };
}
