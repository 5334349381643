import { Box, IconButton, alpha } from "@mui/material";
import { Left, Right } from "../../../../../../icons";
import { useEffect } from "react";

export const PaginationController = ({
  currentPage,
  theme,
  totalPages,
  setCurrentPage,
}) => {
  useEffect(() => {
    // console.log("currentPage", currentPage);
    // console.log("totalPages", totalPages);
  }, [currentPage, totalPages]);

  const handleNextPage = () => {
    const targetPage = currentPage === totalPages ? 1 : currentPage + 1;
    setCurrentPage(targetPage);
  };

  const handlePrevPage = () => {
    const targetPage = currentPage === 1 ? totalPages : currentPage - 1;
    setCurrentPage(targetPage);
  };

  const IconButtonComponent = ({ onClick, isPrev }) => (
    <IconButton
      onClick={onClick}
      sx={{
        height: "32px",
        width: "32px",
        padding: "0",
        marginBottom: "1px",
        borderRadius: "8px",
        color: theme.palette.colors.blue[500],
      }}
    >
      {isPrev ? (
        <Left
          sx={{
            height: "24px",
            width: "24px",
            fill:
              currentPage === 1
                ? alpha(theme.palette.colors.grayScale.black, 0.3)
                : theme.palette.colors.blue[500],
          }}
        />
      ) : (
        <Right
          sx={{
            height: "24px",
            width: "24px",
            fill:
              currentPage === totalPages
                ? alpha(theme.palette.colors.grayScale.black, 0.3)
                : theme.palette.colors.blue[500],
          }}
        />
      )}
    </IconButton>
  );

  const CurrentPageIndicator = () => {
    const pageUnit = 100 / totalPages;
    const marginLeft = (currentPage - 1) * pageUnit;

    return (
      <Box
        display={"flex"}
        height={"2px"}
        width={"100px"}
        sx={{
          background: theme.palette.colors.grayScale[100],
          borderRadius: "8px",
          marginLeft: "8px",
          marginRight: "8px",
        }}
      >
        <Box
          display={"flex"}
          position={"absolute"}
          height={"2px"}
          marginLeft={`${marginLeft}px`}
          width={`${pageUnit}px`}
          sx={{
            background: theme.palette.colors.blue[500],
            borderRadius: "8px",
          }}
        />
      </Box>
    );
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      height={"30px"}
      sx={{
        transform: { xs: "scale(1.2)", sm: "scale(1)" },
        marginLeft: { xs: "6px", md: "0" },
      }}
    >
      <IconButtonComponent onClick={handlePrevPage} isPrev={true} />
      <IconButtonComponent onClick={handleNextPage} isPrev={false} />
      <CurrentPageIndicator />
    </Box>
  );
};
