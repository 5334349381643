import { Box, useMediaQuery, useTheme } from "@mui/material";

interface PartnerCellProps {
  image: string;
}

export const PartnerCell: React.FC<PartnerCellProps> = ({
  image = "placeholder",
}) => {
  const theme = useTheme();
  const isMediumSize = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallSize = useMediaQuery(theme.breakpoints.down("sm"));
  const size = isMediumSize ? "75px" : isSmallSize ? "50px" : "110px";
  return (
    <Box height={size}>
      <img src={image} alt={"value"} height={size} />
    </Box>
  );
};
