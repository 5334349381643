import { Box, Grid, useTheme } from "@mui/material";
import { CustomButton } from "../../ui-elements/CustomButton";
import { HeaderTag } from "../../ui-elements/HeaderTag";
import { Header2 } from "../../ui-elements/typography/Header2";
import { Subtitle } from "../../ui-elements/typography/Subtitle";
import { Spacer } from "../../ui-elements/layout-elements/Spacer";
import { ProgramCell } from "../landing/sections/cells/ProgramCell";
import { useNavigate } from "react-router-dom";
import { programs } from "../programs/programData";
import { ProgramName } from "../../../types/data/IProgram";

interface ProgramsSectionProps {
  displayIndividualButtons?: boolean;
}

const ProgramsSection: React.FC<ProgramsSectionProps> = ({
  displayIndividualButtons = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleButtonClick = (buttonName: string) => {
    navigate("/programs");
  };

  const getProgramWithName = (programName: ProgramName) => {
    const program = programs.find((program) => program.name === programName);
    return program;
  };

  return (
    <Box
      position={"relative"}
      top={0}
      bottom={0}
      left={0}
      right={0}
      justifyContent={"center"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        background: theme.palette.background.lightBackgroundGradient,
        paddingTop: "60px",
      }}
    >
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        maxWidth={"1200px"}
        paddingLeft={"24px"}
        paddingRight={"24px"}
      >
        <HeaderTag text={"Key Programs"} />
        <Spacer height={"24px"} />
        <Header2 text={"Programs and Grants"} />
        <Spacer height={"12px"} />
        <Subtitle
          text={`Empowering innovation, Multisynq's Grants Program offers financial support to developers and institutions creating cutting-edge apps, games, and educational tools.`}
          sx={{ maxWidth: "600px" }}
        />
        <Spacer height={"60px"} />
        <Grid
          container
          spacing={"80px"}
          maxWidth={"1000px"}
          justifyContent={"center"}
          alignItems={"center"}
          // minimum 2 grid items per row
          sx={{ flexWrap: "wrap" }}
        >
          <ProgramCell program={getProgramWithName(ProgramName.IndieDev)} />
          <ProgramCell program={getProgramWithName(ProgramName.Enterprise)} />
          <ProgramCell program={getProgramWithName(ProgramName.University)} />
          <ProgramCell program={getProgramWithName(ProgramName.Educators)} />
        </Grid>
        {displayIndividualButtons && (
          <>
            <Spacer height={"60px"} />
            <CustomButton onClick={() => handleButtonClick("Apply Now")}>
              Explore All Programs
            </CustomButton>
          </>
        )}
        <Spacer height={"80px"} />
      </Box>
    </Box>
  );
};

export default ProgramsSection;
