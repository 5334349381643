import React, { useState, useEffect } from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { FormType, FormData, FormField } from "../../../types/forms";
import { submitForm } from "../../../features/forms/formSubmissionSlice";
import {
  FormSection,
  InputField,
  SubmitButton,
  ErrorMessage,
} from "../FormComponents";
import { programApplicationConfig } from "./programApplicationConfig";
import FormSubmissionFeedback from "../FormSubmissionFeedback";

interface ProgramApplicationFormProps {
  programName: string;
}

const ProgramApplicationForm: React.FC<ProgramApplicationFormProps> = ({
  programName,
}) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormData>({});
  const formState = useAppSelector(
    (state) => state.formSubmission[FormType.PROGRAM_APPLICATION]
  );

  // const fillFormWithDummyData = () => {
  //   const dummyData: FormData = {
  //     sales_program: "University Program",
  //     fullname: "Jane Doe",
  //     email: "jane.doe@example.com",
  //     website: "https://www.janedoeproject.com",
  //     game_title: "Mystic Quest",
  //     game_development_stage: "Production",
  //     game_description: "An immersive fantasy RPG set in a magical world.",
  //     what_engine_platform_are_you_using_for_development: "Unreal",
  //     does_your_game_currently_support_multiplayer_functionality__if_yes__describe_the_multiplayer_featur:
  //       "Yes, the game supports both co-op and competitive multiplayer modes.",
  //     do_you_plan_to_add_multiplayer_features: "No",
  //     how_will_multisynq_benefit_your_game:
  //       "Multisynq will help optimize the game's networking and enhance player experience.",
  //     how_do_you_handle_networking:
  //       "Currently using AWS servers and a custom-built networking solution.",
  //     number_of_team_members_and_their_roles:
  //       "7 members: 3 developers, 1 artist, 1 designer, 1 project manager, 1 QA tester.",
  //     previous_projects_or_experience_in_game_development:
  //       "Previously developed an indie game that was featured on Steam.",
  //     primary_target_audience:
  //       "Adults aged 18-35 who enjoy fantasy RPGs on PC and consoles.",
  //     what_is_your_planned_monetization_model:
  //       "One-time purchase with optional DLC content.",
  //     are_there_any_specific_technical_requirements_or_constraints_for_your_game_:
  //       "Requires high-end GPUs and stable internet for multiplayer.",
  //     specific_multiplayer_features_to_implement:
  //       "Looking to implement matchmaking, voice chat, and leaderboards.",
  //     what_are_your_long_term_development_and_expansion_plans_for_the_game_:
  //       "Planning to release expansions and additional content post-launch.",
  //     what_type_of_support_are_you_looking_for:
  //       "Technical support for optimizing multiplayer performance.",
  //     link_to_a_playable_demo: "https://www.janedoeproject.com/demo",
  //     anything_else_you_would_like_us_to_know:
  //       "We are also exploring VR support for future updates.",
  //     estimated_release_date: "2024-11-01",
  //   };

  //   setFormData(dummyData);
  // };
  // useEffect(() => {
  //   fillFormWithDummyData();
  // }, []);

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const target = e.target as
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement;
    const name = target.name;
    const value =
      target.type === "checkbox"
        ? (target as HTMLInputElement).checked
        : target.value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(
      submitForm({ formType: FormType.PROGRAM_APPLICATION, data: formData })
    );
  };

  if (formState?.isSubmitted) {
    return (
      <FormSubmissionFeedback
        title="Thank you for applying!"
        message={`We will review your application and then will get back to you via the email you used to apply. In the meantime, feel free to reach out to us at support@multisynq.io with any questions.`}
      />
    );
  }

  const sections = [
    { title: `Program`, fields: ["sales_program"] },
    { title: "Personal Information", fields: ["fullname", "email", "website"] },
    {
      title: "Game Information",
      fields: [
        "game_title",
        "game_development_stage",
        "game_description",
        "what_engine_platform_are_you_using_for_development",
        "does_your_game_currently_support_multiplayer_functionality",
        "do_you_plan_to_add_multiplayer_features",
        "how_will_multisynq_benefit_your_game",
        "how_do_you_handle_networking",
      ],
    },
    {
      title: "Team Information",
      fields: [
        "number_of_team_members_and_their_roles",
        "previous_projects_or_experience_in_game_development",
      ],
    },
    {
      title: "Market and Monetization",
      fields: [
        "primary_target_audience",
        "what_is_your_planned_monetization_model",
      ],
    },
    {
      title: "Additional Details",
      fields: [
        "are_there_any_specific_technical_requirements_or_constraints_for_your_game",
        "specific_multiplayer_features_to_implement",
        "what_are_your_long_term_development_and_expansion_plans_for_the_game",
        "what_type_of_support_are_you_looking_for",
      ],
    },
    {
      title: "Showcase",
      fields: ["link_to_a_playable_demo"],
    },
    {
      title: "Additional Information",
      fields: [
        "anything_else_you_would_like_us_to_know",
        "estimated_release_date",
      ],
    },
  ];

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: "100%",
        maxWidth: "900px",
        margin: "auto",
        fontFamily: '"Be Vietnam Pro", sans-serif',
      }}
    >
      {sections.map((section) => (
        <FormSection key={section.title} title={section.title}>
          {section.fields.map((fieldName) => {
            const field = programApplicationConfig.fields.find(
              (f) => f.name === fieldName
            ) as FormField;
            return field ? (
              <InputField
                key={field.name}
                field={field}
                value={formData[field.name] || ""}
                onChange={handleInputChange}
              />
            ) : null;
          })}
        </FormSection>
      ))}

      <SubmitButton
        text={programApplicationConfig.submitButtonText}
        isProcessing={formState?.isProcessing || false}
      />

      {formState?.error && <ErrorMessage message={formState.error} />}
    </Box>
  );
};

export default ProgramApplicationForm;
