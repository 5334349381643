import { FormConfig } from "../../../types/forms";

export const partnershipFormConfig: FormConfig = {
  title: "Partnership Form",
  fields: [
    { name: "company", label: "Company Name", type: "text", required: true },
    {
      name: "fullname",
      label: "Contact Person Name",
      type: "text",
      required: true,
    },
    { name: "email", label: "Email Address", type: "email", required: true },
    {
      name: "partnership_type",
      label: "Partnership Type",
      type: "select",
      options: ["Developer", "Enterprise", "Synchronizer User - Commercial"],
      required: true,
    },
    {
      name: "message",
      label: "Brief Description of Interest/Proposal",
      type: "textarea",
      required: true,
    },
  ],
  submitButtonText: "Submit",
};
