import { Box, lighten, useMediaQuery, useTheme } from "@mui/material";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { Body } from "../../../ui-elements/typography/Body";
import CleanSVG from "../../../../services/CleanSVG";
import { SvgName } from "../../../../images/SvgAssets";
import {
  Decentralization,
  FosteringGrowth,
  SupportingInnovation,
} from "../../../../icons";
import { Header4 } from "../../../ui-elements/typography/Header4";
import { useEffect, useState } from "react";

export const AboutPrinciplesSection = () => {
  const theme = useTheme();
  const textColor = theme.palette.colors.blue[900];
  const textAlign = "left";
  const isSmallOrSmaller = useMediaQuery(theme.breakpoints.down("md"));

  const Column = ({ headerText, bodyText, icon, index }) => {
    const topMarginForIndex = (index) => {
      if (isSmallOrSmaller) {
        return "24px";
      }
      switch (index) {
        case 0:
          return "420px";
        case 1:
          return "485px";
        case 2:
          return "550px";
        default:
          return "0px";
      }
    };

    const leftMarginForIndex = (index) => {
      if (isSmallOrSmaller) {
        return "0px";
      }
      switch (index) {
        case 0:
          return "0px";
        case 1:
          return "24px";
        case 2:
          return "32px";
        default:
          return "0px";
      }
    };

    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        paddingRight={"10px"}
        width={"100%"}
        marginTop={topMarginForIndex(index)}
        marginLeft={leftMarginForIndex(index)}
      >
        <Box
          height={"40px"}
          width={"40px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            borderRadius: "50%",
            border: "1.5px solid",
            borderColor: theme.palette.colors.royalBlue[500],
            fill: theme.palette.colors.royalBlue[500],
            padding: "8px",
          }}
        >
          {icon}
        </Box>
        <Spacer height={"12px"} />
        <Header4 text={headerText} textAlign={textAlign} />
        <Spacer height={"8px"} />
        <Body
          text={bodyText}
          textAlign={textAlign}
          sx={{
            color: lighten(textColor, 0.4),
          }}
        />
      </Box>
    );
  };

  const [leftMargin, setLeftMargin] = useState("-60px");

  const leftMarginForWindowSizes = () => {
    const windowWidth = window.innerWidth;
    const maxWidth = 1000;
    const difference = windowWidth - maxWidth;
    if (difference > 0) {
      const result = isSmallOrSmaller ? -24 : -60;
      return result;
    }

    const margin = isSmallOrSmaller ? -24 : -60;
    const newMargin = margin - difference;

    return newMargin;
  };

  useEffect(() => {
    // when window resizes, adjsut margin
    window.addEventListener("resize", () => {
      setLeftMargin(`${leftMarginForWindowSizes()}px`);
    });

    // cleanup
    return () => {
      window.removeEventListener("resize", () => {
        setLeftMargin(`${leftMarginForWindowSizes()}px`);
      });
    };
  }, []);

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignSelf={"center"}
      width={"100%"}
      height={"100%"}
      maxWidth={"1000px"}
      paddingLeft={{ xs: "24px", sm: "60px" }}
      paddingRight={{ xs: "24px", sm: "60px" }}
      paddingBottom={"60px"}
      marginTop={{
        xs: "-400px",
        sm: "-400px",
        md: "-500px",
      }}
      marginBottom={{
        xs: "-440px",
        sm: "260px",
        md: isSmallOrSmaller ? "200px" : "120px",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={{ xs: "auto", sm: "600px" }}
      >
        <Box
          display={"flex"}
          flexDirection={isSmallOrSmaller ? "column" : "row"}
          alignItems={"flex-start"}
          width={"100%"}
          height={"100%"}
          marginTop={isSmallOrSmaller ? "780px" : "0px"}
        >
          <Column
            headerText={"Empowering Decentralization"}
            bodyText={`We believe in a decentralized future. By eliminating central points of failure, we create a robust and secure network that empowers users globally.`}
            icon={<Decentralization />}
            index={0}
          />
          <Column
            headerText={"Supporting Innovation"}
            bodyText={`Innovation drives progress. We support cutting-edge research and development in synchronization technologies, ensuring our network stays ahead of the curve.`}
            icon={<SupportingInnovation />}
            index={1}
          />
          <Column
            headerText={"Fostering Growth"}
            bodyText={`We are committed to nurturing the Multisynq ecosystem. Through grants, educational programs, and community initiatives, we foster growth and collaboration among developers and users.`}
            icon={<FosteringGrowth />}
            index={2}
          />
        </Box>

        <Box
          display={"flex"}
          position={"absolute"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"600px"}
          sx={{
            paddingTop: "60px",
            marginLeft: { xs: "24px", sm: leftMargin },
            paddingRight: "60px",
            zIndex: -1,
          }}
        >
          <CleanSVG name={SvgName.SquiggleArt} style={{ opacity: 0.7 }} />
        </Box>
      </Box>
    </Box>
  );
};
