import {
  ISuccessStory,
  SuccessStoryName,
} from "../../../types/data/ISuccesStory";
import in_the_footsteps from "../../../images/success/in-the-footsteps.jpg";
import rogue_engine from "../../../images/success/roge-engine-img.jpg";

const tagForSuccessStory = {
  RogueEngine: "rogue-engine",
  InTheFootsteps: "in-the-footsteps",
};

const urlPrefix = process.env.REACT_APP_FRONTEND_URL;
const detailsUrl = {
  RogueEngine: `${urlPrefix}/success-stories?story=${tagForSuccessStory.RogueEngine}`,
  InTheFootsteps: `${urlPrefix}/success-stories?story=${tagForSuccessStory.InTheFootsteps}`,
};

export const successStoryData: ISuccessStory[] = [
  {
    name: SuccessStoryName.RogueEngine,
    title: "Rogue Engine",
    subtitle: "Simplifying Multiplayer Game Development",
    introduction:
      "Rogue Engine is an innovative development environment designed to simplify the creation of web apps and games. With its intuitive drag-and-drop interface, Rogue Engine empowers developers to easily build complex applications without the need for extensive coding.",
    challenge:
      "Multiplayer game development is often a complex and resource-intensive process, requiring developers to manage server infrastructure, write intricate netcode, and overcome synchronization challenges. These barriers can slow down development, increase costs, and limit the accessibility of multiplayer capabilities, particularly for smaller teams and independent developers.",
    solution:
      "Rogue Engine integrated the Multisynq Network directly into its platform, allowing developers to incorporate real-time multiplayer features with just a few clicks. The addition of Rogue Croquet means that developers no longer need to worry about backend infrastructure or complex coding for multiplayer functionality. Instead, they can focus on designing and refining their games, confident that the Multisynq Network will handle all synchronization and real-time interactions with minimal latency. Rogue Engine lowers the barrier of entry while increasing the efficiency of development.",
    results:
      "The integration of Multisynq into Rogue Engine has been transformative. Developers can now create and deploy multiplayer games with latency as low as 15-30ms, without the need for traditional server setups. This ease of use has opened up new possibilities for indie developers, enabling them to bring sophisticated multiplayer experiences to market faster and more cost-effectively. Rogue Engine's partnership with Multisynq has set a new standard for what's possible in game development, making high-quality, real-time multiplayer games accessible to all developers, regardless of their technical expertise.",
    shortSummary:
      "Rogue Engine is transforming the landscape of game development with its drag-and-drop environment that now includes seamless multiplayer capabilities, thanks to its integration with the Multisynq Network. Developers can easily create and launch real-time multiplayer games with minimal effort, cutting down on both costs and time to market.",
    detailsUrl: detailsUrl.RogueEngine,
    image: rogue_engine,
  },
  {
    name: SuccessStoryName.InTheFootsteps,
    title: "In The Footsteps",
    subtitle: "Bringing History to Life with Immersive Learning",
    introduction:
      "In The Footsteps is an educational platform that offers users a deeply immersive way to explore history. Initially inspired by the documentary 'In The Footsteps of Marco Polo' by Denis Belliveau, the platform began as a 2D browser game that utilized the legacy Multisynq Network to deliver synchronized educational experiences.",
    challenge:
      "Transitioning from a 2D game to a fully immersive VR and 3D experience posed significant challenges for In The Footsteps. The team needed to ensure that the new platform could handle real-time interactivity and maintain seamless synchronization for multiplayer educational experiences. Moreover, achieving these goals without relying on costly and complex server infrastructure was crucial to making the platform scalable and accessible.",
    solution:
      "In The Footsteps leveraged its ongoing partnership with the Multisynq Foundation to address these challenges. By using the Multisynq Network, which powers real-time, decentralized synchronization, and integrating it with Unity, In The Footsteps successfully created an immersive VR and 3D experience. This setup allowed the platform to maintain low-latency interactions, enabling users to engage with historical content in a more dynamic and interactive way. The Multisynq Network's decentralized infrastructure ensured that the platform could scale easily, providing robust performance without the need for traditional server setups.",
    results:
      "In The Footsteps now offers a rich, interactive environment where users can explore historical events and figures in real-time, whether through VR or 3D gameplay. The platform's ability to deliver seamless, multiplayer educational experiences has made it a favorite among educators looking for innovative teaching tools. Additionally, the decentralized nature of the Multisynq Network has enabled In The Footsteps to reach a broader audience while keeping operational costs low. This evolution marks a significant milestone in the intersection of education and technology, demonstrating how immersive platforms can enhance learning by bringing history to life.",
    shortSummary:
      "In The Footsteps has evolved from a 2D educational game into a fully immersive VR and 3D platform, using the Multisynq Network and Multisynq 4 Unity package. This transformation allows students and history enthusiasts to explore historical events interactively and in real-time, revolutionizing the way history is taught and experienced.",
    detailsUrl: detailsUrl.InTheFootsteps,
    image: in_the_footsteps,
  },
];
