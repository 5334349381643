import { alpha, Box, useTheme } from "@mui/material";
import { HeaderTag } from "../../../ui-elements/HeaderTag";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Body } from "../../../ui-elements/typography/Body";

export const AboutPurposeSection = () => {
  const theme = useTheme();
  const textColor = theme.palette.colors.blue[900];
  const textAlign = "left";

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignSelf={"center"}
      width={"100%"}
      maxWidth={"1000px"}
    >
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={"space-between"}
        justifyContent={"flex-start"}
        margin={{ xs: "24px", sm: "60px" }}
      >
        <Box
          display={"flex"}
          height={"100%"}
          width={"100%"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          maxWidth={"360px"}
        >
          <HeaderTag text={"Purpose"} />
          <Spacer height={"24px"} />
          <Header2
            text={"What is the Multisynq Foundation?"}
            textAlign={textAlign}
          />
        </Box>
        <Spacer width={"40px"} />
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          <Spacer height={"50px"} />
          <Body
            text={`The Multisynq Foundation is dedicated to pioneering the future of decentralized synchronization infrastructure, with a mission to support and expand the Multisynq Network. Our primary goal is to foster a vibrant ecosystem of developers, Synchronizer hosts, and other stakeholders, ensuring seamless and real-time multi-user experiences across various applications, from gaming to enterprise solutions. By leveraging the innovative Multisynq Protocol, we aim to eliminate the complexities and high costs associated with traditional centralized servers, offering an ultra-low latency and highly efficient alternative that empowers developers to create synchronized applications effortlessly. <br><br> Through comprehensive programs and initiatives, the Multisynq Foundation supports the continuous growth and enhancement of the Multisynq Network. We provide resources, technical assistance, and financial incentives to developers, encouraging them to build on our decentralized platform. Additionally, we incentivize individuals and organizations to become Synqers, hosting Synchronizers and contributing to the network's scalability and robustness. By promoting collaboration and innovation within the Multisynq ecosystem, we strive to establish a globally accessible, secure, and efficient synchronization infrastructure that revolutionizes how people interact and collaborate in real-time digital environments​​​​​.`}
            textAlign={textAlign}
            sx={{
              color: alpha(textColor, 0.8),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
