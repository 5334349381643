import { Box, useTheme } from "@mui/material";
import { HeaderTagText } from "./typography/HeaderTagText";

interface HeaderTagProps {
  text: string;
  sx?: any;
}

export const HeaderTag: React.FC<HeaderTagProps> = ({ text, sx }) => {
  const theme = useTheme();
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"24px"}
      sx={{
        borderRadius: "8px",
        paddingLeft: "10px",
        paddingRight: "10px",
        border: `1px solid ${theme.palette.colors.blue[500]}`,
      }}
    >
      <HeaderTagText
        text={text}
        sx={{
          ...sx,
        }}
      />
    </Box>
  );
};
