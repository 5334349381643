import { Box, lighten, useMediaQuery, useTheme } from "@mui/material";
import { HeaderTag } from "../../../ui-elements/HeaderTag";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Body } from "../../../ui-elements/typography/Body";
import transparen_globe from "../../../../images/partnerships/transparent-globe.png";
import { Dots } from "../../../ui-elements/Dots";
import { DotsLocation } from "../../../../types/ui/dotsTypes";

export const PartnershipsIntroSection = () => {
  const theme = useTheme();
  const textColor = theme.palette.colors.grayScale.white;

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const CONTAINER_HEIGHT = isSmall ? 200 : 400;
  const PADDING = 24;
  const IMAGE_SIZE = CONTAINER_HEIGHT - PADDING - PADDING;

  const textAlign = isSmall ? "center" : "left";
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignSelf={"center"}
      width={"100%"}
      height={"100%"}
      maxWidth={"1000px"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        padding={{ xs: "24px", md: "60px" }}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          padding={`${PADDING}px`}
          sx={{
            background: theme.palette.background.headerBackgroundGradient,
            borderRadius: "8px",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            width={"100%"}
            height={"100%"}
            flexDirection={{ xs: "column", md: "row" }}
          >
            {isSmall && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignSelf={"center"}
                width={"100px"}
                paddingBottom={"24px"}
              >
                <HeaderTag
                  text={"Partnership"}
                  sx={{
                    color: textColor,
                  }}
                />
              </Box>
            )}
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignSelf={{ xs: "center", md: "center" }}
              minHeight={`${IMAGE_SIZE}px`}
              minWidth={`${IMAGE_SIZE}px`}
              maxHeight={`${IMAGE_SIZE}px`}
              maxWidth={`${IMAGE_SIZE}px`}
              flexDirection={"column"}
            >
              <img
                src={transparen_globe}
                alt={"programs header"}
                style={{ objectFit: "contain", width: "100%", height: "100%" }}
              />
            </Box>
            <Spacer width={"60px"} height={"0px"} />
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              justifyContent={"center"}
              paddingRight={"24px"}
            >
              {!isSmall && (
                <HeaderTag
                  text={"Partnership"}
                  sx={{
                    color: textColor,
                  }}
                />
              )}
              <Spacer height={"24px"} />
              <Header2
                text={"The Multisynq Advantage"}
                textAlign={textAlign}
                sx={{
                  color: textColor,
                  fontSize: "1.8rem",
                }}
              />
              <Spacer height={"24px"} />
              <Body
                text={`The Multisynq Advantage lies in its decentralized synchronization infrastructure, which eliminates the need for complex server setups by moving computation to the client-side. This approach reduces latency, cuts costs by over 80%, and simplifies development, enabling real-time, multi-user experiences across games, web apps, and enterprise solutions.`}
                textAlign={textAlign}
                sx={{
                  color: lighten(textColor, 0.4),
                  paddingLeft: { xs: "48px", md: "0px" },
                  paddingRight: { xs: "48px", md: "0px" },
                  paddingBottom: { xs: "12px", md: "0px" },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Dots dotsLocation={DotsLocation.right} topOffsetOverride="250px" />
    </Box>
  );
};
