import { useEffect } from "react";
import { PageProps } from "../../page-manager/PageManager";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import JoinSection from "../shared-sections/JoinSection";
import { SuccessStoriesBodySection } from "./sections/SuccessStoriesBodySection";
import { useLocationToGetSuccessStory } from "../../helpers/useLocationToGetSuccessStory";
import { successStoryData } from "./successStoryData";

const SuccessStoriesPage = ({ showPageWrapper }: PageProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let activeSuccessStory = useLocationToGetSuccessStory();

  if (!activeSuccessStory) {
    activeSuccessStory = successStoryData[0];
  }

  return (
    <>
      <SuccessStoriesBodySection activeSuccessStory={activeSuccessStory} />
      <JoinSection />
    </>
  );
};

export default SuccessStoriesPage;
