import { useState, useEffect } from "react";
import NotificationPopup from "../popups/NotificationPopup";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { setShowErrorNotification } from "../../features/ui/errorSlice";

const ErrorMessengerPopup = () => {
  const [showModal, setShowModal] = useState(false);
  const errorMessengerSlice = useAppSelector((state) => state.errorMessenger);
  const dispatch = useAppDispatch();

  const onDismiss = () => {
    setShowModal(false);
    dispatch(setShowErrorNotification(false));
  };

  useEffect(() => {
    setShowModal(errorMessengerSlice.showErrorNotification);
  }, [errorMessengerSlice.showErrorNotification]);

  return showModal ? (
    <NotificationPopup
      title={errorMessengerSlice.errorMessage.title}
      message={errorMessengerSlice.errorMessage.message}
      triggerPopup={true}
      onDismiss={onDismiss}
    />
  ) : (
    <div></div>
  );
};

export default ErrorMessengerPopup;
