import { useEffect } from "react";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import { Box } from "@mui/material";
import { Spacer } from "../../ui-elements/layout-elements/Spacer";
import { PartnershipsContactPageHeader } from "./PartnershipsContactPageHeader";
import PartnershipForm from "../../forms/partnership/PartnershipForm";

const PartnershipsContactPage = ({ showPageWrapper }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
    window.scrollTo(0, 0);
  }, [dispatch, showPageWrapper]);

  return (
    <>
      <PartnershipsContactPageHeader />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        alignSelf={"center"}
        marginTop={"30px"}
        justifyContent="center"
        height={"100%"}
        width={"100%"}
        maxWidth={"1200px"}
        paddingLeft={"24px"}
        paddingRight={"24px"}
      >
        <PartnershipForm />
      </Box>
      <Spacer height={"60px"} />
    </>
  );
};

export default PartnershipsContactPage;
