import { Box } from "@mui/material";
import { HeaderTag } from "../../../ui-elements/HeaderTag";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Body } from "../../../ui-elements/typography/Body";
import { Header4 } from "../../../ui-elements/typography/Header4";
import { CustomButton } from "../../../ui-elements/CustomButton";
import { useNavigate } from "react-router-dom";
import { IProgram } from "../../../../types/data/IProgram";
import { CustomBulletList } from "../../../ui-elements/layout-elements/CustomBulletList";
import { Dots } from "../../../ui-elements/Dots";
import { DotsLocation } from "../../../../types/ui/dotsTypes";

interface ProgramDetailDetailsProps {
  program: IProgram;
}

export const ProgramDetailDetails = ({
  program,
}: ProgramDetailDetailsProps) => {
  const textAlign = "left";

  const handleButtonClick = () => {
    window.location.href = program.applicationUrl;
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignSelf={"center"}
      width={"100%"}
      height={"100%"}
      maxWidth={"1000px"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        paddingLeft={{ xs: "24px", sm: "40px", md: "60px" }}
        paddingRight={{ xs: "24px", sm: "40px", md: "60px" }}
      >
        <HeaderTag text={"Program"} />
        <Spacer height={"24px"} />
        <Header2
          text={`What the Multisynq ${program.pageTitle} Provides`}
          textAlign={textAlign}
          sx={{
            fontSize: "1.9rem",
          }}
        />
        <Spacer height={"24px"} />
        <Body text={program.programSummary} textAlign={textAlign} />
        <Spacer height={"24px"} />
        <Header4 text={"What Multisynq Offers"} textAlign={textAlign} />
        <CustomBulletList items={program.offerings} />
        <Header4 text={"Program Benefits"} textAlign={textAlign} />
        <CustomBulletList items={program.benefits} />
        <Header4 text={"How to Apply"} textAlign={textAlign} />
        <CustomBulletList items={program.howToQualify} />
        <Spacer height={"24px"} />
        <CustomButton onClick={() => handleButtonClick()}>
          Apply Now
        </CustomButton>
        <Spacer height={"60px"} />
        <Dots dotsLocation={DotsLocation.right} topOffsetOverride="250px" />
      </Box>
    </Box>
  );
};
