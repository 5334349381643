import { FormConfig } from "../../../types/forms";

export const programOptions = [
  "University Program",
  "Indie Dev Program",
  "Educators Program",
  "Enterprise Program",
];

export const developmentStages = [
  "Pre-production",
  "Production",
  "Alpha",
  "Beta",
  "Released",
];

export const enginePlatforms = [
  "Unity",
  "Unreal",
  "Godot",
  "React",
  "WebGl",
  "Javascript",
  "O3DE",
  "Other",
];

export const yesNoOptions = ["Yes", "No"];

export const programApplicationConfig: FormConfig = {
  title: "Sales Program Application",
  fields: [
    {
      name: "sales_program",
      label: "What Sales Program are you applying to:",
      type: "select",
      options: programOptions,
      required: true,
    },
    { name: "fullname", label: "Full name", type: "text", required: true },
    { name: "email", label: "Email", type: "email", required: true },
    { name: "website", label: "Website URL", type: "url" },
    { name: "game_title", label: "Game Title", type: "text", required: true },
    {
      name: "game_development_stage",
      label: "Game Development Stage",
      type: "select",
      options: developmentStages,
      required: true,
    },
    {
      name: "game_description",
      label: "Game Description",
      type: "textarea",
      required: true,
    },
    {
      name: "what_engine_platform_are_you_using_for_development",
      label: "What Engine/Platform are you using for Development:",
      type: "select",
      options: enginePlatforms,
      required: true,
    },
    {
      name: "does_your_game_currently_support_multiplayer_functionality__if_yes__describe_the_multiplayer_featur",
      label:
        "Does your game currently support multiplayer functionality? If yes, describe the multiplayer features:",
      type: "textarea",
      required: true,
    },
    {
      name: "do_you_plan_to_add_multiplayer_features",
      label: "If not, do you plan to add multiplayer features in the future?",
      type: "select",
      options: yesNoOptions,
      required: true,
    },
    {
      name: "how_will_multisynq_benefit_your_game",
      label:
        "How do you think the Multisynq multiplayer product could benefit your game/studio?",
      type: "textarea",
      required: true,
    },
    {
      name: "how_do_you_handle_networking",
      label:
        "How do you currently handle networking and server infrastructure for your game?",
      type: "textarea",
      required: true,
    },
    {
      name: "number_of_team_members_and_their_roles",
      label: "Number of team members and their roles",
      type: "textarea",
      required: true,
    },
    {
      name: "previous_projects_or_experience_in_game_development",
      label: "Previous projects or experience in game development",
      type: "textarea",
    },
    {
      name: "primary_target_audience",
      label:
        "Who is your primary target audience (age group, interests, gaming platform preferences)?",
      type: "textarea",
      required: true,
    },
    {
      name: "what_is_your_planned_monetization_model",
      label:
        "What is your planned monetization model (e.g., free-to-play, subscription, in-app purchases)?",
      type: "textarea",
      required: true,
    },
    {
      name: "are_there_any_specific_technical_requirements_or_constraints_for_your_game_",
      label:
        "Are there any specific technical requirements or constraints for your game?",
      type: "textarea",
    },
    {
      name: "specific_multiplayer_features_to_implement",
      label:
        "What specific multiplayer features are you looking to implement (e.g., matchmaking, leaderboards, co-op modes)?",
      type: "textarea",
    },
    {
      name: "what_are_your_long_term_development_and_expansion_plans_for_the_game_",
      label:
        "What are your long-term development and expansion plans for the game?",
      type: "textarea",
    },
    {
      name: "what_type_of_support_are_you_looking_for",
      label:
        "What type of support are you looking for from the Multisynq team (e.g., technical support, marketing assistance)?",
      type: "textarea",
    },
    {
      name: "link_to_a_playable_demo",
      label:
        "Share a link to a playable demo, presentation, or video of your game:",
      type: "textarea",
      required: true,
    },
    {
      name: "anything_else_you_would_like_us_to_know",
      label:
        "Is there anything else you would like us to know about your game or your needs?",
      type: "textarea",
    },
    {
      name: "estimated_release_date",
      label: "Do you have an estimated release date?",
      type: "date",
    },
  ],
  submitButtonText: "Submit Application",
};
