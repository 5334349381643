import { Typography } from "@mui/material";

export const FooterCopyrightSection = ({ theme }) => {
  return (
    <Typography
      variant="body2"
      sx={{
        color: theme.palette.colors.grayScale.white,
        opacity: 0.8,
        fontSize: "0.75rem",
        textAlign: "left",
      }}
    >
      © 2024 Croquet Labs. All rights reserved.
    </Typography>
  );
};
