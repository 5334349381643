import { Box, useTheme } from "@mui/material";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Subtitle } from "../../../ui-elements/typography/Subtitle";
import { PageHeader } from "../../../page-manager/PageHeader";

export const AboutHeaderSection: React.FC = () => {
  const theme = useTheme();
  return (
    <PageHeader>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignSelf={"center"}
        paddingLeft={{ xs: "24px", sm: "60px" }}
        paddingRight={{ xs: "24px", sm: "60px" }}
        paddingTop={{ xs: "24px", sm: "0px" }}
        paddingBottom={{ xs: "24px", sm: "0px" }}
        width={"100%"}
        height={"100%"}
        maxWidth={"1000px"}
      >
        <Header2
          text="About Us: Multisynq Foundation"
          textAlign="left"
          color={theme.palette.colors.grayScale.white}
          sx={{ marginBottom: "16px" }}
        />

        <Subtitle
          text={`The Multisynq Foundation aims to foster the success of the Multisynq Network by supporting developers, hosting educational initiatives, and driving community engagement. Our mission is to decentralize infrastructure and promote innovation in collaborative applications.`}
          textAlign="left"
          color={theme.palette.colors.grayScale.white}
          sx={{
            opacity: 0.8,
          }}
        />
      </Box>
    </PageHeader>
  );
};
