import React from "react";
import SVG_COMPONENTS, { SvgName } from "../images/SvgAssets";

interface CleanSVGProps {
  name: SvgName;
  style?: React.CSSProperties;
}

const CleanSVG: React.FC<CleanSVGProps> = ({ name, style }) => {
  const SvgComponent = SVG_COMPONENTS[name];

  return SvgComponent ? <SvgComponent style={style} /> : null;
};

export default CleanSVG;
