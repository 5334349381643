import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IProgram } from "../../types/data/IProgram";
import { programs } from "../pages/programs/programData";

export const useLocationToGetProgram = () => {
  const location = useLocation();
  const [activeProgram, setActiveProgram] = useState<IProgram | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const programName = queryParams.get("program");

    if (programName) {
      console.log(programName);
      const program = programs.find((program) => program.name === programName);
      if (program) {
        setActiveProgram(program);
      }
    }
  }, [location.search]);

  return activeProgram;
};
