import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CleanSVG from "../../../../services/CleanSVG";
import { SvgName } from "../../../../images/SvgAssets";

export const StackedImage = ({ image }) => {
  const theme = useTheme();
  const isSmallSize = useMediaQuery(theme.breakpoints.down("md"));
  const isXSmallSize = useMediaQuery(theme.breakpoints.down("sm"));
  const BOX_SIZE = isXSmallSize ? 150 : isSmallSize ? 250 : 290;
  const ROOT_SQUARE = isXSmallSize ? 250 : isSmallSize ? 400 : 500;
  const BORDER_RADIUS = 2;
  const BASE_RIGHT_OFFSET = isXSmallSize ? 35 : 70;
  const BASE_BOTTOM_OFFSET = isXSmallSize ? 35 : 70;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
    >
      <Box
        width={ROOT_SQUARE + "px"}
        height={ROOT_SQUARE + "px"}
        position="relative"
        sx={{
          // scale down if sm
          transform: { xs: "scale(1.0)", md: "scale(1.0)" },
        }}
      >
        <Box
          width={ROOT_SQUARE + "px"}
          height={ROOT_SQUARE + "px"}
          position="absolute"
          top="0"
          left="0"
          zIndex={1} // Set zIndex to ensure this is in the background
        >
          <CleanSVG
            name={SvgName.ProgramDetailSquiggles}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>

        <Box
          component="img"
          src={image}
          width={BOX_SIZE}
          height={BOX_SIZE}
          position="absolute"
          bottom={BASE_BOTTOM_OFFSET + "px"}
          right={BASE_RIGHT_OFFSET + (isSmallSize ? 20 : 55) + "px"}
          sx={{
            borderRadius: BORDER_RADIUS,
            zIndex: 3,
          }}
        />

        <Box
          width={BOX_SIZE}
          height={BOX_SIZE}
          position="absolute"
          bottom={BASE_BOTTOM_OFFSET + (isSmallSize ? 20 : 50) + "px"}
          right={BASE_RIGHT_OFFSET + "px"}
          sx={{
            borderRadius: BORDER_RADIUS,
            background: theme.palette.background.headerBackgroundGradient,
            zIndex: 2,
          }}
        />
      </Box>
    </Box>
  );
};
