import { useEffect } from "react";
import { PageProps } from "../../page-manager/PageManager";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import JoinSection from "../shared-sections/JoinSection";
import { PartnershipsHeaderSection } from "./sections/PartnershipsHeaderSection";
import { PartnershipsIntroSection } from "./sections/PartnershipsIntroSection";
import { PartnershipsApplySection } from "./sections/PartnershipsApplySection";
import { PartnershipsDetailSection } from "./sections/PartnershipsDetailSection";

const PartnershipsPage = ({ showPageWrapper }: PageProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PartnershipsHeaderSection />
      <PartnershipsIntroSection />
      <PartnershipsApplySection />
      <PartnershipsDetailSection />
      <JoinSection />
    </>
  );
};

export default PartnershipsPage;
