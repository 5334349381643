import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import confirmationPopupSlice from "../features/ui/confirmationPopupSlice";
import errorSlice from "../features/ui/errorSlice";
import publicUiStateSlice from "../features/ui/publicUiStateSlice";
import pageManagerStateSlice from "../features/ui/pageManagerStateSlice";
import formSubmissionSlice from "../features/forms/formSubmissionSlice";

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const store = configureStore({
  reducer: {
    confirmationPopup: confirmationPopupSlice,
    errorMessenger: errorSlice,
    publicUiState: publicUiStateSlice,
    pageManager: pageManagerStateSlice,
    formSubmission: formSubmissionSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload.headers"],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export default store;
