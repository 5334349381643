import { Box, useTheme } from "@mui/material";
import CleanSVG from "../../../services/CleanSVG";
import { SvgName } from "../../../images/SvgAssets";

interface DottedImageBlockProps {
  height: string;
  width: string;
  forceSize?: boolean;
  children: React.ReactNode;
  sx?: any;
}

export const DottedImageBlock = ({
  height,
  width,
  forceSize,
  children,
  sx,
}: DottedImageBlockProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        background: theme.palette.background.headerBackgroundGradient,
        height: height,
        width: width,
        minWidth: forceSize ? width : "auto",
        minHeight: forceSize ? height : "auto",
        overflow: "hidden",
        borderRadius: "8px",
        ...sx,
      }}
    >
      <CleanSVG
        name={SvgName.AngleDots}
        style={{
          height: "150px",
          width: "auto",
          position: "absolute",
          top: "0",
          left: "0",
        }}
      />
      <CleanSVG
        name={SvgName.AngleDots}
        style={{
          height: "150px",
          width: "auto",
          position: "absolute",
          bottom: "0",
          right: "0",
          transform: "scale(-1, -1)",
        }}
      />
      {children}
    </Box>
  );
};
