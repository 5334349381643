import { ReactComponent as Dots } from "../images/dots.svg";
import { ReactComponent as LogoImg } from "./landing/logo-img.svg";
import { ReactComponent as DiscordLogo } from "./socials/discord-logo.svg";
import { ReactComponent as DiscordLogoWhite } from "./socials/discord-logo-white.svg";
import { ReactComponent as InstagramLogo } from "./socials/insta-logo.svg";
import { ReactComponent as InstagramLogoWhite } from "./socials/insta-logo-white.svg";
import { ReactComponent as XLogo } from "./socials/x-logo.svg";
import { ReactComponent as XLogoWhite } from "./socials/x-logo-white.svg";
import { ReactComponent as Rocket } from "./rocket.svg";
import { ReactComponent as MapDots } from "./map-dots.svg";
import { ReactComponent as MapDotsWhite } from "./world-dots-white.svg";
import { ReactComponent as AngleDots } from "./angle-dots.svg";
import { ReactComponent as SquiggleArt } from "./about/squiggle-art.svg";
import { ReactComponent as ProgramDetailSquiggles } from "./programs/program-detail-squiggles.svg";

export enum SvgName {
  Dots = "dots",
  LogoImg = "logo-img",
  DiscordLogo = "discord-logo",
  DiscordLogoWhite = "discord-logo-white",
  InstagramLogo = "insta-logo",
  InstagramLogoWhite = "insta-logo-white",
  XLogo = "x-logo",
  XLogoWhite = "x-logo-white",
  Rocket = "rocket",
  MapDots = "map-dots",
  WorldDotsWhite = "map-dots-white",
  AngleDots = "angle-dots",
  SquiggleArt = "squiggle-art",
  ProgramDetailSquiggles = "program-detail-squiggles",
}

const SVG_COMPONENTS = {
  [SvgName.Dots]: Dots,
  [SvgName.LogoImg]: LogoImg,
  [SvgName.DiscordLogo]: DiscordLogo,
  [SvgName.DiscordLogoWhite]: DiscordLogoWhite,
  [SvgName.InstagramLogo]: InstagramLogo,
  [SvgName.InstagramLogoWhite]: InstagramLogoWhite,
  [SvgName.XLogo]: XLogo,
  [SvgName.XLogoWhite]: XLogoWhite,
  [SvgName.Rocket]: Rocket,
  [SvgName.MapDots]: MapDots,
  [SvgName.WorldDotsWhite]: MapDotsWhite,
  [SvgName.AngleDots]: AngleDots,
  [SvgName.SquiggleArt]: SquiggleArt,
  [SvgName.ProgramDetailSquiggles]: ProgramDetailSquiggles,
};

export default SVG_COMPONENTS;
