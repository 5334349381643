import { Box, Grid, Divider, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/store";
import { processInternalLinkClicked } from "./footer-links/processInternalLinkClick";
import {
  FooterConnectSection,
  FooterInfoSection,
  FooterLogoSection,
  FooterCopyrightSection,
} from "./footer-sections";

const SiteFooter = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const scrollToTopOfWindow = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: this makes the scroll smooth
    });
  };

  const handleClick = processInternalLinkClicked(dispatch, navigate);

  return (
    <Box
      component="footer"
      position={"relative"}
      bottom={0}
      sx={{
        py: 2,
        px: 2,
        backgroundColor: theme.palette.primary.main,
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        paddingTop="8px"
        paddingLeft={"8px"}
        paddingRight={"8px"}
      >
        <FooterLogoSection scrollToTopOfWindow={scrollToTopOfWindow} />
        <FooterInfoSection handleClick={handleClick} />
        <FooterConnectSection handleClick={handleClick} />
      </Grid>
      <Divider
        sx={{
          my: 2,
          borderColor: theme.palette.background.divider,
          opacity: 0.3,
        }}
      />
      <FooterCopyrightSection theme={theme} />
    </Box>
  );
};

export default SiteFooter;
