import { Grid } from "@mui/material";
import { FooterSectionTitle } from "../FooterSectionTitle";
import { InternalLink } from "../footer-links/InternalLink";
import { ExternalLink } from "../footer-links/ExternalLink";

export const FooterInfoSection = ({ handleClick }) => {
  return (
    <Grid item xs={12} sm={2} marginBottom={"16px"}>
      <FooterSectionTitle>Info</FooterSectionTitle>
      <InternalLink
        label="Privacy Policy"
        path="/privacy"
        onClick={handleClick}
      />
      <ExternalLink label="Multisynq Network" path="https://www.multisynq.io" />
      <ExternalLink label="Croquet Labs" path="https://www.croquet.io" />
    </Grid>
  );
};
