import { Box } from "@mui/material";

export const Spacer = ({ width = "0px", height = "0px", sx = null }) => {
  return (
    <Box
      display={"flex"}
      width={width}
      height={height}
      sx={{
        ...sx,
      }}
    />
  );
};
