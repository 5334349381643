import * as React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { lighten, useTheme } from "@mui/material/styles";

export interface CustomButtonProps extends ButtonProps {
  onClick: () => void;
  setHovering?: (hovering: boolean) => void;
  highlightColorOverride?: string;
  sx?: any;
  children: React.ReactNode;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  setHovering,
  highlightColorOverride,
  sx,
  children,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      onMouseEnter={setHovering ? () => setHovering(true) : undefined}
      onMouseLeave={setHovering ? () => setHovering(false) : undefined}
      variant="contained"
      sx={{
        borderRadius: "8px",
        height: "40px",
        width: "180px",
        textTransform: "none",
        backgroundColor: theme.palette.colors.royalBlue[500],
        fontSize: "0.8rem",
        fontWeight: 600,
        lineHeight: "1.1",
        "&:hover": {
          backgroundColor: highlightColorOverride
            ? highlightColorOverride
            : lighten(theme.palette.primary.main, 0.15),
          boxShadow: "none",
          transition: "0.15s",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "none",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
