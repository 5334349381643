import { useEffect, useState } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  setCurrentPage,
  setScrollPosition,
  setTotalPages,
} from "../../../../../../features/ui/pageManagerStateSlice";

const ITEM_WIDTH = 368;

export const usePageManager = (listRef, itemRefs, filteredExamples) => {
  const dispatch = useAppDispatch();

  const pageManager = useAppSelector((state) => state.pageManager);
  const { currentPage, totalPages } = pageManager;

  const [showShadow, setShowShadow] = useState(false);

  const refreshTotalPages = () => {
    const itemWidth = ITEM_WIDTH;
    const containerWidth = listRef.current.clientWidth || 0;
    const itemsPerPage = Math.floor(containerWidth / itemWidth);
    const newTotalPages = Math.ceil(filteredExamples.length / itemsPerPage);

    if (itemsPerPage < 1) {
      dispatch(setTotalPages(filteredExamples.length));
      return filteredExamples.length;
    }

    dispatch(setTotalPages(newTotalPages));
    return itemsPerPage;
  };

  useEffect(() => {
    if (!listRef.current || !itemRefs.current[0]) {
      return;
    }

    const itemsPerPage = refreshTotalPages();
    const targetIndex = (currentPage - 1) * itemsPerPage;

    if (itemRefs.current[targetIndex]) {
      const targetItem = itemRefs.current[targetIndex];
      const listRect = listRef.current.getBoundingClientRect();
      const targetRect = targetItem.getBoundingClientRect();

      const scrollFrom = listRef.current.scrollLeft;
      const scrollTo = targetRect.left - listRect.left + scrollFrom;

      const startTime = performance.now();
      const duration = 450;

      const animateScroll = (currentTime) => {
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);

        try {
          listRef.current.scrollLeft =
            scrollFrom + (scrollTo - scrollFrom) * progress;
        } catch (e) {
          //console.error(e);
        }

        if (timeElapsed < duration) {
          window.requestAnimationFrame(animateScroll);
        } else {
          try {
            dispatch(setScrollPosition(listRef.current.scrollLeft));
          } catch (e) {
            //console.error(e);
          }
        }
      };

      window.requestAnimationFrame(animateScroll);
    }

    const hasOverflow =
      listRef.current.scrollWidth > listRef.current.clientWidth;
    setShowShadow(hasOverflow);
  }, [currentPage, filteredExamples.length, dispatch]);

  useEffect(() => {
    const handleResize = () => {
      if (listRef.current) {
        refreshTotalPages();
        dispatch(setCurrentPage(1));
        const hasOverflow =
          listRef.current.scrollWidth > listRef.current.clientWidth;
        setShowShadow(hasOverflow);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  const callSetCurrentPage = (page) => {
    dispatch(setCurrentPage(page));
  };

  return {
    showShadow,
    currentPage,
    totalPages,
    callSetCurrentPage,
  };
};
