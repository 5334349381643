import { Box, useTheme } from "@mui/material";

import { HeaderTag } from "../../../ui-elements/HeaderTag";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Subtitle } from "../../../ui-elements/typography/Subtitle";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { EventsScrollview } from "./events-section/blocks/EventsScrollview";

const LandingEventsSection: React.FC = () => {
  const theme = useTheme();
  return (
    <Box
      position={"relative"}
      top={0}
      bottom={0}
      left={0}
      right={0}
      justifyContent={"center"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        background: {
          xs: theme.palette.background.lightBackgroundGradient,
          md: "none",
        },
      }}
    >
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        maxWidth={"1200px"}
        paddingLeft={"24px"}
        paddingRight={"24px"}
      >
        <Spacer height={"60px"} />
        <HeaderTag text={"Events"} />
        <Spacer height={"24px"} />
        <Header2 text={"Recent Announcements"} textAlign="left" />
        <Spacer height={"12px"} />
        <Subtitle
          text={`Stay informed about the latest updates, events, and news from the Multisynq Foundation.`}
          textAlign="left"
          sx={{ maxWidth: "400px" }}
        />
        <Spacer height={"48px"} />
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          <EventsScrollview />
        </Box>
        <Spacer height={"80px"} />
      </Box>
    </Box>
  );
};

export default LandingEventsSection;
