import { Typography, useTheme } from "@mui/material";

export const FooterSectionTitle = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        fontWeight: "900",
        fontSize: "12px",
        color: theme.palette.colors.grayScale.white,
        paddingBottom: "8px",
      }}
    >
      {children}
    </Typography>
  );
};
