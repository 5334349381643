import { Box } from "@mui/material";
import { Navbar } from "../ui-elements/nav-menu/Navbar";
import SiteFooter from "../menus/site-footer/SiteFooter";
import { useAppSelector } from "../../app/store";

export const PageWrapper = ({ children }) => {
  const publicUiStateSlice = useAppSelector((state) => state.publicUiState);
  if (!publicUiStateSlice.showPageWrapper) {
    return children;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Navbar />
      {children}
      <Box sx={{ flexGrow: 1 }} />
      <SiteFooter />
    </Box>
  );
};
