import { GlobalStyles } from "@mui/system";
import { colors } from "./colors";

export const GlobalStylesComponent = ({
  isDarkMode,
}: {
  isDarkMode: boolean;
}) => (
  <GlobalStyles
    styles={{
      body: {
        backgroundColor: isDarkMode
          ? colors.grayScale[800]
          : colors.grayScale.white,
        "*::selection": {
          backgroundColor: isDarkMode
            ? "rgba(255, 255, 255, 0.1)"
            : "rgba(0, 0, 0, 0.1)", // White or black with 20% opacity
        },
      },
      ".parent-container": {
        position: "fixed",
        display: "flex",
        overflow: "hidden",
        width: "100%",
        height: "100%",
      },
      "*::-webkit-scrollbar": {
        width: "6px !important",

        height: "6px !important",
        backgroundColor: "transparent !important",
      },
      "*::-webkit-scrollbar-thumb": {
        borderRadius: "8px !important",

        backgroundColor: isDarkMode
          ? colors.grayScale[700]
          : colors.grayScale[100],
        marginTop: "10px !important",
        marginBottom: "0px !important",
      },
      "*::-webkit-scrollbar-track": {
        borderRadius: "8px !important",
        backgroundColor: "transparent !important",
        marginTop: "10px !important",
        marginBottom: "10px !important",
      },

      "@keyframes pulse": {
        "0%": {
          opacity: 0.9,
        },
        "50%": {
          opacity: 0.3,
        },
        "100%": {
          opacity: 0.9,
        },
      },
      "ul > li:last-of-type": { marginBottom: "1.5em" },
      "ol > li:last-of-type": { marginBottom: "1.5em" },
      "ul > li:first-of-type": { marginTop: "1.5em" },
      "ol > li:first-of-type": { marginTop: "1.5em" },
    }}
  />
);
