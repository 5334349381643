import { Box, useTheme } from "@mui/material";
import CleanSVG from "../../services/CleanSVG";
import { SvgName } from "../../images/SvgAssets";
import { CustomDivider } from "../ui-elements/CustomDivider";

interface PageHeaderProps {
  children: React.ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      alignContent={"center"}
      justifyContent={"space-between"}
      sx={{
        minHeight: "337px",
        width: "100%",
        position: "relative",
        background: theme.palette.background.headerBackgroundGradient,
        overflow: "hidden",
      }}
    >
      <CleanSVG
        name={SvgName.AngleDots}
        style={{
          height: "200px",
          width: "auto",
          position: "absolute",
          top: "0",
          right: "0",
        }}
      />
      <CleanSVG
        name={SvgName.AngleDots}
        style={{
          height: "200px",
          width: "auto",
          position: "absolute",
          bottom: "0",
          left: "0",
          transform: "scale(-1, -1)",
        }}
      />
      <CustomDivider
        sx={{
          color: theme.palette.colors.grayScale.white,
          fill: theme.palette.colors.grayScale.white,
          border: "0.5px solid",
          opacity: 1.0,
        }}
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignSelf={"center"}
        width={"100%"}
        height={"100%"}
        maxWidth={"100%"}
      >
        {children}
      </Box>
      <Box />
    </Box>
  );
};
