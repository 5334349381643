import { List, ListItem } from "@mui/material";
import { Body } from "../typography/Body";

export const CustomBulletList = ({ items }) => {
  return (
    <List
      sx={{
        padding: 0,
        marign: 0,
        marginTop: "-12px",
      }}
    >
      {items.map((item, index) => (
        <ListItem
          key={index}
          sx={{
            display: "list-item",
            listStyleType: "disc",
            margin: 0,
            marginBottom: "4px",
            padding: 0,
            marginLeft: "32px",
          }}
        >
          <Body text={item} textAlign="left" />
        </ListItem>
      ))}
    </List>
  );
};
