import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import createGlobalTheme from "./customTheme/globalTheme";
import { GlobalStylesComponent } from "./customTheme/GlobalStyles";
import PageManager from "./components/page-manager/PageManager";
import ErrorMessengerPopup from "./components/helpers/ErrorMessengerPopup";
import { PageWrapper } from "./components/page-manager/PageWrapper";

const App: React.FC = () => {
  const theme = createGlobalTheme(false);
  const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

  useEffect(() => {
    const handleUnhandledRejection = (event) => {
      // Prevent the default handling (e.g., logging to the console)
      event.preventDefault();
    };

    window.addEventListener("unhandledrejection", handleUnhandledRejection);

    return () => {
      window.removeEventListener(
        "unhandledrejection",
        handleUnhandledRejection
      );
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStylesComponent isDarkMode={false} />
      <Router>
        <ErrorMessengerPopup />
        <PageWrapper>
          <PageManager />
        </PageWrapper>
      </Router>
      {/* <CookieValidator /> */}
    </ThemeProvider>
  );
};

export default App;
