import { Link, useTheme } from "@mui/material";
import { customLinkTheme } from "./linkStyle";

interface InternalLinkProps {
  label: string;
  path: string;
  onClick: (path: string) => void;
}

export const InternalLink = ({ label, path, onClick }: InternalLinkProps) => {
  const theme = useTheme();

  return (
    <Link
      onClick={() => onClick(path)}
      paddingBottom={"5px"}
      underline="hover"
      sx={customLinkTheme(theme)}
    >
      {label}
    </Link>
  );
};
