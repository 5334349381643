import { Box, useTheme } from "@mui/material";
import { PageHeader } from "../../../page-manager/PageHeader";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Subtitle } from "../../../ui-elements/typography/Subtitle";

export const ContactPageHeader = () => {
  const theme = useTheme();
  return (
    <PageHeader>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignSelf={"center"}
        paddingLeft={"24px"}
        paddingRight={"24px"}
        paddingTop={{ xs: "24px", sm: "0px" }}
        paddingBottom={{ xs: "24px", sm: "0px" }}
        width={"100%"}
        height={"100%"}
        maxWidth={"900px"}
      >
        <Header2
          text={`Let's Build the Future Together`}
          textAlign="left"
          color={theme.palette.colors.grayScale.white}
          sx={{ marginBottom: "16px" }}
        />

        <Subtitle
          text={`We're dedicated to driving innovation in creating a decentralized, multi-user web experience. Whether you're a developer seeking resources, a partner interested in collaboration, or someone who wants to learn more about our mission, we're here to connect.`}
          textAlign="left"
          color={theme.palette.colors.grayScale.white}
          sx={{
            opacity: 0.8,
          }}
        />
      </Box>
    </PageHeader>
  );
};
