import { lighten, darken } from "@mui/material/styles";

const colorBases = {
  royalBlue: "#002AFF",
  blue: "#006AFF",
  babyBlue: "#BFDAFF",
  periwinkle: "#3A69FB",
  red: "#ED254E",
  purple: "#3C00BB",
  teal: "#00C2FF",
  pink: "#D800B9",
  lightPurple: "#A157FA",
  magenta: "#FD0056",
  darkPurple: "#360568",
  mediumPurple: "#7000FF",
  grayScale: {
    50: "#F7F7F7",
    75: "#F0F0F0",
    100: "#D3D3D3",
    200: "#A9A9A9",
    300: "#808080",
    400: "#585858",
    500: "#303030",
    600: "#282828",
    700: "#1F1F1F",
    800: "#171717",
    900: "#0F0F0F",
    white: "#FFFFFF",
    black: "#000000",
  },
};

const createShades = (color: string) => {
  return {
    50: lighten(color, 0.85),
    100: lighten(color, 0.7),
    200: lighten(color, 0.5),
    300: lighten(color, 0.3),
    400: lighten(color, 0.1),
    500: color,
    600: darken(color, 0.1),
    700: darken(color, 0.3),
    800: darken(color, 0.5),
    900: darken(color, 0.7),
  };
};

export const colors = {
  royalBlue: createShades(colorBases.royalBlue),
  blue: createShades(colorBases.blue),
  babyBlue: createShades(colorBases.babyBlue),
  periwinkle: createShades(colorBases.periwinkle),
  red: createShades(colorBases.red),
  purple: createShades(colorBases.purple),
  teal: createShades(colorBases.teal),
  pink: createShades(colorBases.pink),
  lightPurple: createShades(colorBases.lightPurple),
  magenta: createShades(colorBases.magenta),
  darkPurple: createShades(colorBases.darkPurple),
  mediumPurple: createShades(colorBases.mediumPurple),
  grayScale: {
    ...colorBases.grayScale,
  },
} as const;
