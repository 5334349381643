import { useEffect } from "react";
import { PageProps } from "../../page-manager/PageManager";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import { ProgramDetailHeader } from "./sections/ProgramDetailHeader";
import { ProgramDetailDetails } from "./sections/ProgramDetailDetails";
import JoinSection from "../shared-sections/JoinSection";
import { useLocationToGetProgram } from "../../helpers/useLocationToGetProgram";

const ProgramsDetailPage = ({ showPageWrapper }: PageProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);
  const activeProgram = useLocationToGetProgram();

  if (!activeProgram) {
    return null;
  }

  return (
    <>
      <ProgramDetailHeader program={activeProgram} />
      <ProgramDetailDetails program={activeProgram} />
      <JoinSection />
    </>
  );
};

export default ProgramsDetailPage;
