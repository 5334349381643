import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IErrorMessage } from "../../types/ui/uiTypes";

const initialState = {
  showErrorNotification: false,
  errorMessage: null as IErrorMessage | null,
};

// show error notification async thunk
export const showErrorNotification = createAsyncThunk(
  "errorMessenger/showErrorNotification",
  async (errorMessage: IErrorMessage, { dispatch }) => {
    dispatch(setErrorMessage(errorMessage));
    dispatch(setShowErrorNotification(true));
  }
);

const errorMessengerSlice = createSlice({
  name: "errorMessenger",
  initialState: initialState,
  reducers: {
    setShowErrorNotification: (state, action) => {
      const show = action.payload;
      if (!show) {
        state.errorMessage = null;
      }

      state.showErrorNotification = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    clearErrorMessage: (state) => {
      state.errorMessage = null;
      state.showErrorNotification = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showErrorNotification.fulfilled, (state, action) => {
      // todo - handle error message?
    });
  },
});

export const { setShowErrorNotification, setErrorMessage } =
  errorMessengerSlice.actions;

export default errorMessengerSlice.reducer;
