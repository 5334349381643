import { Box, lighten, useMediaQuery, useTheme } from "@mui/material";
import { HeaderTag } from "../../../ui-elements/HeaderTag";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Body } from "../../../ui-elements/typography/Body";
import { partnershipsData } from "../partnershipsData";
import { Header3 } from "../../../ui-elements/typography/Header3";
import { Header4 } from "../../../ui-elements/typography/Header4";
import { CustomDivider } from "../../../ui-elements/CustomDivider";
import { Dots } from "../../../ui-elements/Dots";
import { DotsLocation } from "../../../../types/ui/dotsTypes";

export const PartnershipCard = ({ title, text, image }) => {
  const theme = useTheme();
  const textColor = theme.palette.colors.blue[900];

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const CONTAINER_HEIGHT = isSmall ? 300 : 350;
  const PADDING = 36;
  const IMAGE_SIZE = CONTAINER_HEIGHT - PADDING - PADDING;

  const textAlign = isSmall ? "center" : "left";

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignContent={"center"}
      width={"100%"}
      height={"100%"}
      flexDirection={{ xs: "column", md: "row" }}
      marginBottom={"60px"}
    >
      <Box
        display={"flex"}
        justifyContent={{ xs: "flex-start", md: "center" }}
        alignSelf={"center"}
        minHeight={`${IMAGE_SIZE}px`}
        minWidth={`${IMAGE_SIZE}px`}
        maxHeight={`${IMAGE_SIZE}px`}
        maxWidth={`${IMAGE_SIZE}px`}
        padding={`${PADDING}px`}
        flexDirection={"column"}
        sx={{
          // add border of 1px that's blue[900] alpha 0.2
          border: `1px solid ${lighten(textColor, 0.8)}`,
          borderRadius: "8px",
        }}
      >
        <img
          src={image}
          alt={title}
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        paddingLeft={{ xs: "24px", sm: "48px", md: "60px" }}
        paddingRight={{ xs: "24px", sm: "48px", md: "60px" }}
        paddingTop={{ xs: "24px", md: "0px" }}
      >
        <Header2
          text={title}
          textAlign={textAlign}
          sx={{
            color: textColor,
            fontSize: "1.8rem",
          }}
        />
        <Spacer height={"12px"} />
        <Body
          text={text}
          textAlign={textAlign}
          sx={{
            color: lighten(textColor, 0.4),
          }}
        />
      </Box>
    </Box>
  );
};

export const PartnershipsDetailSection = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignSelf={"center"}
      width={"100%"}
      height={"100%"}
      maxWidth={"1000px"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        paddingLeft={{ xs: "24px", md: "60px" }}
        paddingRight={{ xs: "24px", md: "60px" }}
      >
        <CustomDivider />
        <Spacer height={"60px"} />
        <HeaderTag text={"How it Works"} />
        <Spacer height={"12px"} />
        <Header3 text={"Partnerships"} textAlign="left" />
        <Spacer height={"48px"} />
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          height={"100%"}
        >
          {partnershipsData.map((partnership, index) => (
            <PartnershipCard
              key={index}
              title={partnership.title}
              text={partnership.text}
              image={partnership.image}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
