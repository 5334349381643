import { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
  darken,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { MobileMenuDrawer } from "../mobile-menu/MobileMenuDrawer";
import NavMenuButton from "./NavMenuButton";
import logo_white_long from "../../../images/logo-white.png";
import { MenuOpen } from "@mui/icons-material";

export const Navbar = ({}) => {
  const location = useLocation();
  const isAuthPage = location.pathname === "/auth";

  const navigate = useNavigate();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect drawerOpen
  useEffect(() => {
    //console.log("drawerOpen", drawerOpen);
  }, [drawerOpen]);

  const handleMenuClick = (viewName) => {
    setDrawerOpen(false);

    if (viewName.toLowerCase() === "about us") {
      return navigate("/about");
    }

    if (viewName.toLowerCase() === "contact us") {
      return navigate("/contact");
    }
    return navigate(viewName);
  };

  const menuItems = (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        paddingLeft: "10px",
        alignItems: "flex-start",
        minWidth: "200px",
      }}
    >
      <NavMenuButton viewName={"About Us"} onClick={handleMenuClick} />
      <NavMenuButton viewName={"Programs"} onClick={handleMenuClick} />
      <NavMenuButton viewName={"Partnerships"} onClick={handleMenuClick} />
      <Button
        onClick={() => handleMenuClick("Contact Us")}
        disableRipple
        sx={{
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          marginRight: theme.spacing(1),
          marginLeft: theme.spacing(1),
          fontSize: "0.8rem",
          color: "inherit",
          backgroundColor: theme.palette.colors.blue[500],
          boxShadow: "none",
          borderRadius: "40px",
          transition: "color 0.0s",
          "&:hover": {
            backgroundColor: theme.palette.colors.blue[300],
          },
        }}
      >
        {"Contact Us"}
      </Button>
      {/* <ButtonWithPopover viewName={"programs"} onClick={handleMenuClick} /> */}
    </Box>
  );

  if (isAuthPage) {
    return null;
  }

  const backgroundThemeColor = theme.palette.colors.teal[500];
  const rB = parseInt(backgroundThemeColor.slice(1, 3), 16);
  const gB = parseInt(backgroundThemeColor.slice(3, 5), 16);
  const bB = parseInt(backgroundThemeColor.slice(5, 7), 16);

  // Get the RGB values for the theme color
  const iconThemeColor = theme.palette.colors.grayScale.white;
  const r = parseInt(iconThemeColor.slice(1, 3), 16);
  const g = parseInt(iconThemeColor.slice(3, 5), 16);
  const b = parseInt(iconThemeColor.slice(5, 7), 16);

  // Calculate color transition from white to the theme color based on scroll position
  const interpolateColor = (start, end, factor) =>
    start + (end - start) * factor;
  const scrollFactor = Math.min(scrollPosition / 200, 1);
  const interpolatedR = Math.round(interpolateColor(255, r, scrollFactor));
  const interpolatedG = Math.round(interpolateColor(255, g, scrollFactor));
  const interpolatedB = Math.round(interpolateColor(255, b, scrollFactor));
  const iconColor = `rgb(${interpolatedR}, ${interpolatedG}, ${interpolatedB})`;

  return (
    <AppBar
      position="relative"
      sx={{
        background: theme.palette.background.headerBackgroundGradient,
        boxShadow: "none",
      }}
    >
      <Toolbar>
        <Box
          display={{ xs: "flex", md: "none" }}
          onClick={() => {
            navigate("/");
          }}
          sx={{
            marginRight: "auto",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <img src={logo_white_long} alt="Logo" style={{ height: "25px" }} />
        </Box>
        <Box
          display={{ xs: "none", md: "flex" }}
          onClick={() => {
            navigate("/");
          }}
          sx={{
            marginRight: "auto",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <img src={logo_white_long} alt="Logo" style={{ height: "32px" }} />
        </Box>
        {!drawerOpen && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setDrawerOpen(!drawerOpen)}
            sx={{
              // blur the background when the menu is open
              backdropFilter: "blur(20px)",
              backgroundColor: `rgba(${rB}, ${gB}, ${bB}, ${Math.min(
                scrollPosition / 200,
                0.8
              )})`,
              position: "fixed",
              top: "8px",
              right: "12px",
              height: "40px",
              width: "40px",
              zIndex: 1300,
              display: { xs: "block", md: "none" },
              opacity: drawerOpen ? 0 : 1,
              transition: "opacity 0.5s",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: `rgba(${rB}, ${gB}, ${bB}, ${Math.min(
                  scrollPosition / 200,
                  0.9
                )})`,
                color: darken(iconColor, 0.05),
              },
            }}
          >
            <MenuOpen />
          </IconButton>
        )}
        {isSmallScreen ? (
          <MobileMenuDrawer
            handleMenuClick={handleMenuClick}
            setDrawerOpen={setDrawerOpen}
            drawerOpen={drawerOpen}
          />
        ) : (
          <Box sx={{ marginLeft: "auto" }}>{menuItems}</Box>
        )}
      </Toolbar>
    </AppBar>
  );
};
