import React from "react";
import { Box, useTheme } from "@mui/material";
import CleanSVG from "../../services/CleanSVG";
import { SvgName } from "../../images/SvgAssets";
import { DotsLocation } from "../../types/ui/dotsTypes";

interface DotsProps {
  dotsLocation: DotsLocation;
  topOffsetOverride?: string;
}

export const Dots: React.FC<DotsProps> = ({
  dotsLocation,
  topOffsetOverride,
}) => {
  const theme = useTheme();
  const isLeft = dotsLocation === "left";

  return (
    <Box
      sx={{
        position: "absolute",
        top: topOffsetOverride ? topOffsetOverride : "25px",
        [isLeft ? "left" : "right"]: 0,
        width: "100%",
        height: "900px",
        overflow: "hidden",
        pointerEvents: "none",
        zIndex: 0,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "900px",
          height: "900px",
          [isLeft ? "left" : "right"]: "-450px",
        }}
      >
        <CleanSVG
          name={SvgName.Dots}
          style={{
            position: "absolute",
            fill: theme.palette.colors.royalBlue[500],
            opacity: 0.12,
            width: "100%",
            height: "100%",
            transform: `rotate(45deg)`,
          }}
        />
      </Box>
    </Box>
  );
};
