import { Grid } from "@mui/material";
import { FooterSectionTitle } from "../FooterSectionTitle";
import { ExternalLink } from "../footer-links/ExternalLink";
import { InternalLink } from "../footer-links/InternalLink";

export const FooterConnectSection = ({ handleClick }) => {
  return (
    <Grid item xs={12} sm={2} marginBottom={"16px"}>
      <FooterSectionTitle>Connect</FooterSectionTitle>
      <InternalLink label="Questions" path="/contact" onClick={handleClick} />
      <ExternalLink
        label="Instagram"
        path="https://www.instagram.com/multisynq/"
      />
      <ExternalLink label="Follow Us On X" path="https://x.com/multisynq" />
      <ExternalLink
        label="Discord Community"
        path="https://multisynq.io/discord"
      />
    </Grid>
  );
};
