import { Link, useTheme } from "@mui/material";
import { customLinkTheme } from "./linkStyle";

interface ExternalLinkProps {
  label: string;
  path: string;
}

export const ExternalLink = ({ label, path }: ExternalLinkProps) => {
  const theme = useTheme();
  return (
    <Link
      href={path}
      target="_blank"
      rel="noopener noreferrer"
      paddingBottom={"5px"}
      underline="hover"
      sx={customLinkTheme(theme)}
    >
      {label}
    </Link>
  );
};
