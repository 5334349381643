import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeEmail: "",
  showPageWrapper: true,
  signInMessage: "",
};

const publicUiStateSlice = createSlice({
  name: "publicUiState",
  initialState: initialState,
  reducers: {
    setShowPageWrapper: (state, action) => {
      state.showPageWrapper = action.payload;
    },
    setSignInMessage: (state, action) => {
      state.signInMessage = action.payload;
    },
  },
});

export const { setShowPageWrapper, setSignInMessage } =
  publicUiStateSlice.actions;
export default publicUiStateSlice.reducer;
