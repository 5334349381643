class EmailService {
  static encodeMailtoDetails(subject, body) {
    const email = "support@multisynq.io"; // Updated email address
    return `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  }

  static sendFeedback() {
    const subject = "Multisynq Feedback";
    const body = `
  [What feature is this regarding?]
  [What did you enjoy the most?]
  [What can be improved?]
  `;
    window.open(this.encodeMailtoDetails(subject, body), "_blank");
  }

  static askQuestion() {
    const subject = "Multisynq Question";
    const body = `
  [Please provide as much detail about your question as possible. Thanks!]`;
    window.open(this.encodeMailtoDetails(subject, body), "_blank");
  }

  static reportBug() {
    const subject = "Multisynq Bug Report";
    const body = `
[Please specify the feature or part of the application and provide the following details]:
  
  - Description of the bug:
  - Steps to reproduce:
  - Expected result:
  - Actual result:
  - Any screenshots or additional information:
  `;
    window.open(this.encodeMailtoDetails(subject, body), "_blank");
  }
}

export default EmailService;
