import { Typography, useTheme } from "@mui/material";

interface Header1Props {
  text: string;
  textAlign?: "center" | "left" | "right";
  color?: string;
  sx?: any;
}

export const Header1: React.FC<Header1Props> = ({
  text = "placeholder",
  textAlign = "center",
  color = null,
  sx = null,
}) => {
  const theme = useTheme();
  const colorValue = theme.palette.text.primary;

  return (
    <Typography
      fontSize={{ xs: "1.8rem", sm: "2.0rem", md: "2.2rem", lg: "2.6rem" }}
      fontWeight={"800"}
      textAlign={textAlign}
      width={"100%"}
      sx={{
        color: color ? color : colorValue,
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};
