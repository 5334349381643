import { Box, Grid, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import logo_white from "../../../../images/logo-white.png";

export const FooterLogoSection = ({ scrollToTopOfWindow }) => {
  return (
    <Grid
      item
      xs={12}
      sm={1}
      marginRight={"64px"}
      marginLeft={(xs) => (xs ? "0px" : "16px")}
      marginBottom={"16px"}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <Link
          component={RouterLink}
          to="/"
          onClick={scrollToTopOfWindow}
          sx={{
            display: "block",
            width: "140px",
            height: "20px",
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          <img
            src={logo_white}
            alt="Logo"
            style={{
              height: "24px",
            }}
          />
        </Link>
      </Box>
    </Grid>
  );
};
