// image, title, description. Left aligned

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Spacer } from "../../../../ui-elements/layout-elements/Spacer";
import { Header3 } from "../../../../ui-elements/typography/Header3";
import { Body } from "../../../../ui-elements/typography/Body";
import { Header4 } from "../../../../ui-elements/typography/Header4";

interface ValueCellProps {
  headerText: string;
  bodyText: string;
  icon: React.ReactNode;
}

export const ValueCell: React.FC<ValueCellProps> = ({
  headerText,
  bodyText,
  icon,
}) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("md"));
  const textAlign = isMobileSize ? "center" : "left";

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={{ xs: "center", md: "flex-start" }}
      justifyContent={"flex-start"}
      height={"100%"}
      paddingRight={"8px"}
      paddingLeft={"8px"}
      maxWidth={{ xs: "360px", md: undefined }}
      marginTop={{ xs: "32px", md: "0px" }}
    >
      <Box
        height={"70px"}
        width={"70px"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          borderRadius: "50%",
          border: "1.5px solid",
          borderColor: theme.palette.colors.royalBlue[500],
          fill: theme.palette.colors.royalBlue[500],
          padding: "10px",
        }}
      >
        {icon}
      </Box>
      <Spacer height={"16px"} />
      <Header4 text={headerText} textAlign={textAlign} />
      <Spacer height={"16px"} />
      <Body
        text={bodyText}
        textAlign={textAlign}
        sx={{ opacity: "0.5", paddingRight: "8px" }}
      />
    </Box>
  );
};
