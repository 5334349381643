import { useEffect } from "react";
import { PageProps } from "../../page-manager/PageManager";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import { AboutHeaderSection } from "./sections/AboutHeaderSection";
import { AboutPurposeSection } from "./sections/AboutPurposeSection";
import PartnerSection from "../shared-sections/LandingPartnerSection";
import { AboutPrinciplesSection } from "./sections/AboutPrinciplesSection";
import JoinSection from "../shared-sections/JoinSection";

const AboutPage = ({ showPageWrapper }: PageProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AboutHeaderSection />
      <AboutPurposeSection />
      <AboutPrinciplesSection />
      <PartnerSection />
      <JoinSection />
    </>
  );
};

export default AboutPage;
