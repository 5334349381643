import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FormSubmissionState, FormType, FormData } from "../../types/forms";
import { apiSubmitForm } from "../../routes/formRoutes";

const initialState: FormSubmissionState = {};

export const submitForm = createAsyncThunk(
  "formSubmission/submitForm",
  async (
    { formType, data }: { formType: FormType; data: FormData },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiSubmitForm(formType, data);
      if (response.status === 200) {
        return { formType, data: response.data };
      } else {
        return rejectWithValue({ formType, error: "Form submission failed" });
      }
    } catch (error) {
      return rejectWithValue({
        formType,
        error: "An error occurred while submitting the form",
      });
    }
  }
);

const formSubmissionSlice = createSlice({
  name: "formSubmission",
  initialState,
  reducers: {
    resetFormSubmission: (state, action: { payload: FormType }) => {
      const formType = action.payload;
      state[formType] = {
        isProcessing: false,
        isSubmitted: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitForm.pending, (state, action) => {
        const formType = action.meta.arg.formType;
        state[formType] = {
          isProcessing: true,
          isSubmitted: false,
          error: null,
        };
      })
      .addCase(submitForm.fulfilled, (state, action) => {
        const { formType } = action.payload;
        state[formType] = {
          isProcessing: false,
          isSubmitted: true,
          error: null,
        };
      })
      .addCase(submitForm.rejected, (state, action) => {
        const { formType, error } = action.payload as {
          formType: FormType;
          error: string;
        };
        state[formType] = {
          isProcessing: false,
          isSubmitted: false,
          error,
        };
      });
  },
});

export const { resetFormSubmission } = formSubmissionSlice.actions;
export default formSubmissionSlice.reducer;
