import { Box, Typography, useTheme } from "@mui/material";

interface HeaderTagTextProps {
  text: string;
  sx?: any;
}

export const HeaderTagText: React.FC<HeaderTagTextProps> = ({
  text = "placeholder",
  sx = undefined,
}) => {
  const theme = useTheme();
  return (
    <Typography
      fontSize={"0.7rem"}
      color={theme.palette.colors.blue[900]}
      fontWeight={"800"}
      width={"100%"}
      sx={{
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};
