import { Box, useTheme } from "@mui/material";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Subtitle } from "../../../ui-elements/typography/Subtitle";
import { PageHeader } from "../../../page-manager/PageHeader";

export const ProgramsHeaderSection: React.FC = () => {
  const theme = useTheme();
  return (
    <PageHeader>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignSelf={"center"}
        paddingLeft={{ xs: "24px", sm: "60px" }}
        paddingRight={{ xs: "24px", sm: "60px" }}
        paddingTop={{ xs: "24px", sm: "0px" }}
        paddingBottom={{ xs: "24px", sm: "0px" }}
        width={"100%"}
        height={"100%"}
        maxWidth={"1000px"}
      >
        <Header2
          text="Multisynq Programs: Grants and Funding"
          textAlign="left"
          color={theme.palette.colors.grayScale.white}
          sx={{ marginBottom: "16px" }}
        />

        <Subtitle
          text={`From free access to secure Synchronizers to exclusive grants and technical support, the Multisynq Foundation's targeted Programs aim to simplify multiplayer development and accelerate the successful launch of products leveraging the power of the Multisynq Network.`}
          textAlign="left"
          color={theme.palette.colors.grayScale.white}
          sx={{
            opacity: 0.8,
          }}
        />
      </Box>
    </PageHeader>
  );
};
