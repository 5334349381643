import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import PopupBase from "./PopupBase";

interface NotificationPopupProps {
  triggerPopup?: boolean;
  title?: string;
  message?: string;
  onDismiss?: () => void;
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({
  triggerPopup = false,
  title = "Your session has expired.",
  message = "Please log back in.",
  onDismiss,
}) => {
  const [isOpen, setIsOpen] = useState(triggerPopup);

  const handleClose = () => {
    setIsOpen(false);
    if (onDismiss) {
      onDismiss();
    }
  };

  function handleKeyPress(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      handleClose();
    }
  }

  return isOpen ? (
    <PopupBase open={isOpen} onClose={handleClose}>
      <Grid container direction="column" justifyContent="center" spacing={3}>
        <Grid item>
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Poppins-Regular",
              fontSize: "12px",
              textAlign: "center",
              paddingBottom: "6px",
            }}
          >
            {message}
          </Typography>
        </Grid>
        <Grid item sx={{ gridColumn: "1 / -1", textAlign: "center" }}>
          <Button
            sx={{
              color: "white",
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
              width: "100%",
            }}
            autoFocus={true}
            disableFocusRipple={true}
            onClick={handleClose}
            onKeyDown={handleKeyPress}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </PopupBase>
  ) : null;
};

export default NotificationPopup;
