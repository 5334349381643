import { alpha, Typography, useTheme } from "@mui/material";
import DOMPurify from "dompurify";

interface SubtitleProps {
  text: string;
  textAlign?: "center" | "left" | "right";
  color?: string;
  sx?: any;
}

export const Subtitle: React.FC<SubtitleProps> = ({
  text = "placeholder",
  textAlign = "center",
  color = null,
  sx = null,
}) => {
  const sanitizedText = DOMPurify.sanitize(text);
  const theme = useTheme();
  const colorValue = theme.palette.text.primary;

  return (
    <Typography
      fontSize={"1.2rem"}
      textAlign={textAlign}
      width={"100%"}
      sx={{
        color: color ? color : alpha(colorValue, 0.75),
        ...sx,
      }}
      dangerouslySetInnerHTML={{ __html: sanitizedText }}
    />
  );
};
