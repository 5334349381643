import {
  HubspotProgramName,
  IProgram,
  ProgramName,
} from "../../../types/data/IProgram";
import indieDevProgramImg from "../../../images/programs/indie-developer-program-img.png";
import enterpriseProgramImg from "../../../images/programs/enterprise-program-img.png";
import universityProgramImg from "../../../images/programs/university-program-img.png";
import educationProgramImg from "../../../images/programs/education-program-img.png";
import { Education, Enterprise, Indie, University } from "../../../icons";

const urlPrefix = process.env.REACT_APP_FRONTEND_URL;
const programUrls = {
  indieDevProgram: `${urlPrefix}/programs/program-details?program=${ProgramName.IndieDev}`,
  enterpriseProgram: `${urlPrefix}/programs/program-details?program=${ProgramName.Enterprise}`,
  universityProgram: `${urlPrefix}/programs/program-details?program=${ProgramName.University}`,
  educatorsProgram: `${urlPrefix}/programs/program-details?program=${ProgramName.Educators}`,
};

const applicationUrls = {
  indieDevProgram: `${urlPrefix}/programs/apply?program=${ProgramName.IndieDev}`,
  enterpriseProgram: `${urlPrefix}/programs/apply?program=${ProgramName.Enterprise}`,
  universityProgram: `${urlPrefix}/programs/apply?program=${ProgramName.University}`,
  educatorsProgram: `${urlPrefix}/programs/apply?program=${ProgramName.Educators}`,
};

export const programs: IProgram[] = [
  {
    offerings: [
      "Powerful multiplayer platform: low-latency (target 15-30ms), real-time collaboration.",
      "Immersive Multiuser Environments: Interactive VR, AR, or 3D.",
      "Serverless and Backend Development-Free: Focus solely on frontend UX.",
      "Free network usage for development.",
    ],
    benefits: [
      "80% lower costs of multiplayer synchronization services compared to competitors.",
      "No server requirement, higher performance, client-side architecture, and faster development times.",
      "Grants for qualifying early launch developers ranging from $5k - $25k USD.",
    ],
    howToQualify: [
      "We are extending the Indie Dev Program to only 100 studios.",
      "Applicants must be willing to share any non-private and non-personal data related operations or performance of the network with Multisynq.",
      "Multisynq retains the right to terminate this agreement at any time.",
      "To apply, email indiedev@multisynq.io or click the button below.",
    ],
    name: ProgramName.IndieDev,
    image: indieDevProgramImg,
    url: programUrls.indieDevProgram,
    pageTitle: "Indie Dev Program",
    pageSummary:
      "Empowering indie developers with cost-effective multiplayer solutions and technical assistance.",
    pageIntro:
      "The Indie Developer Program provides access to the Multisynq network for development purposes at no cost, eliminating the need for a dedicated server.",
    programSummary:
      "Participants can create multiplayer games and immersive environments with integrated multiuser interfaces, enjoying lower costs and faster time to market. To qualify, developers must be willing to share non-private data with Multisynq.",
    shortDescription:
      "Fuel your indie game's success with free network usage during development, $25/TB post-launch, and grants up to $25k. Compatible with Unity, React, JavaScript. Limited to 100 studios.",
    icon: <Indie />,
    applicationUrl: applicationUrls.indieDevProgram,
    hubspotProgramName: HubspotProgramName.IndieDev,
  },
  {
    offerings: [
      "Powerful multiplayer platform: low-latency, real-time collaboration.",
      "Shared Experience Data Models: Real-time dataviz collaboration.",
      "Spatial Collaboration Presence: Virtual immersive engagement.",
      "Immersive Multiuser Environments: Interactive VR, AR, or 3D.",
      "Serverless and Backend Development-Free: Focus solely on frontend UX.",
    ],
    benefits: [
      "Compliance with security, regulatory and risk policies.",
      "No server requirement, higher performance, client-side architecture, and faster development times.",
      "Private secure custom reflector for development and client deployment.",
    ],
    howToQualify: [
      "We are extending the Enterprise Program to qualified entities.",
      "Applicants must be willing to share any non-private and non-personal data related to operations or performance of the network with Multisynq.",
      "Multisynq retains the right to terminate this agreement at any time.",
      "To apply, email enterprise@multisynq.io or click the button below.",
    ],
    name: ProgramName.Enterprise,
    image: enterpriseProgramImg,
    url: programUrls.enterpriseProgram,
    pageTitle: "Enterprise Program",
    pageSummary:
      "A robust solution for enterprises needing secure and scalable multiplayer environments.",
    pageIntro:
      "The Enterprise Program provides secure multi-user synchronizers for proof-of-concept development, with frameworks and technical support tailored to enterprise needs.",
    programSummary:
      "Participants can build immersive environments, real-time collaborative workflows, and global spatial presence. Qualified entities must share non-private data with Multisynq.",
    shortDescription: `Streamline Enterprise projects with low-latency multiplayer, immersive VR/AR, and serverless development. Get a private reflector for 6 months at $20k. Limited to qualified entities for research and prototyping.`,
    icon: <Enterprise />,
    applicationUrl: applicationUrls.enterpriseProgram,
    hubspotProgramName: HubspotProgramName.Enterprise,
  },
  {
    offerings: [
      "Free Secure Collaboration: Get your own private multi-user synchronizer for app and game development, at no cost.",
      "Real-Time Engagement: Experience low-latency, real-time collaboration.",
      "Interactive Data Visualization: Collaborate on data visualization in real-time.",
      "Virtual Immersion: Engage in immersive VR, AR, or 3D environment.",
    ],
    benefits: [
      "No server requirement, higher performance, client-side architecture, and faster development times.",
      "Free Developer Tools: Access licenses and deployment support for free.",
      "Support and Resources: Tutorials, documentation, and dedicated Discord support.",
    ],
    howToQualify: [
      "We are extending the University Program to only 25 accredited educational institutions.",
      "Applicants must be willing to share any non-private and non-personal data related operations or performance of the network with Multisynq.",
      "Multisynq retains the right to terminate this agreement at any time.",
      "To apply, email university@multisynq.io or click the button below.",
    ],
    name: ProgramName.University,
    image: universityProgramImg,
    url: programUrls.universityProgram,
    pageTitle: "University Program",
    pageSummary:
      "Enhancing educational institutions with free tools for secure collaboration and immersive learning.",
    pageIntro:
      "The University Program offers secure, private multi-user Synchronizers at no cost, along with frameworks for various platforms and technical support.",
    programSummary:
      "Participants must use Multisynq for educational purposes only, with a requirement to share non-private data with Multisynq. The program is limited to 25 accredited institutions.",
    shortDescription: `Empower your students with free tools for low-latency app development, no backend required. Compatible with Unity, React, JavaScript. Free licenses, tutorials, and support. Limited to 25 accredited institutions.`,
    icon: <University />,
    applicationUrl: applicationUrls.universityProgram,
    hubspotProgramName: HubspotProgramName.University,
  },
  {
    offerings: [
      "Free Network Usage for Development: Unlimited use of the Multisynq network for product development.",
      "Free Secure Synchronizers for Your Clients: Private multi-user synchronizer for onsite app deployment, at no cost.",
      "Real-Time Engagement: Experience low-latency, real-time collaboration.",
      "Virtual Immersion: Engage in immersive VR, AR, or 3D environments.",
    ],
    benefits: [
      "No server requirement, higher performance, client-side architecture, and faster development times.",
      "Free Developer Tools: Access licenses and deployment support for free.",
      "Support and Resources: Tutorials, documentation, and dedicated Discord support.",
    ],
    howToQualify: [
      "We are extending the Educators Program to only 100 accredited educational institutions.",
      "Applicants must be willing to share any non-private and non-personal data related operations or performance of the network with Multisynq.",
      "Multisynq retains the right to terminate this agreement at any time.",
      "To apply, email education@multisynq.io or click the button below.",
    ],
    name: ProgramName.Educators,
    image: educationProgramImg,
    url: programUrls.educatorsProgram,
    pageTitle: "Multisynq Educators Program",
    pageSummary:
      "Supporting educators with free network usage and development tools for educational purposes.",
    pageIntro:
      "The Multisynq Educators Program provides free access to the network and secure multi-user synchronizers, tailored for educational institutions.",
    programSummary:
      "Participants can create multiplayer games and immersive environments for educational purposes only. The program is limited to 100 accredited institutions, with a requirement to share non-private data with Multisynq.",
    shortDescription: `Unlock unlimited free network usage, private synchronizers, and low-latency collaboration for educational app development. Free tools, licenses, and support included. Limited to 100 accredited educational institutions.`,
    icon: <Education />,
    applicationUrl: applicationUrls.educatorsProgram,
    hubspotProgramName: HubspotProgramName.Educators,
  },
];
