import { Box, List, useTheme } from "@mui/material";
import { useRef } from "react";
import { PaginationController } from "./PaginationController";
import { usePageManager } from "./usePageManager";
import { useAppSelector } from "../../../../../../app/store";
import { EventCell } from "../../cells/EventCell";

export const EventsScrollview = () => {
  const theme = useTheme();
  const listRef = useRef<HTMLUListElement>(null);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);

  const filteredData = useAppSelector(
    (state) => state.pageManager.filteredData
  );

  const { showShadow, currentPage, totalPages, callSetCurrentPage } =
    usePageManager(listRef, itemRefs, filteredData);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
      width={"100%"}
      height={"100%"}
      sx={{
        position: "relative",
        overflowY: "hidden",
        "&::after": {
          content: '""',
          position: "absolute",
          bottom: "48px",
          right: 0,
          width: "20px",
          height: "138px",
          boxShadow:
            totalPages !== currentPage && showShadow
              ? "inset -10px 0 10px -10px rgba(0, 0, 0, 0.2)"
              : "none",
          pointerEvents: "none",
        },
      }}
    >
      <List
        ref={listRef}
        sx={{
          margin: "0px",
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          height: "50%",
          overflowX: { xs: "scroll", sm: "hidden" },
          overflowY: "hidden",
          scrollbarWidth: "none",
          touchAction: "none",
        }}
      >
        {filteredData.map((event, index) => (
          <li
            key={event.url}
            ref={(el) => (itemRefs.current[index] = el)}
            style={{
              listStyleType: "none",
              flex: "0 0 auto",
              marginRight: "0px",
              marginTop: "0px",
              marginLeft: index === 0 ? "0px" : "10px",
              marginBottom: "8px",
            }}
          >
            <EventCell event={event} />
          </li>
        ))}
      </List>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginBottom={"12px"}
        width={"100%"}
      >
        <PaginationController
          currentPage={currentPage}
          theme={theme}
          totalPages={totalPages}
          setCurrentPage={callSetCurrentPage}
        />
      </Box>
    </Box>
  );
};
