import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "../pages/landing/LandingPage";
import NotFoundPage from "../pages/NotFoundPage";
import UnsubscribePage from "../pages/UnsubscribePage";
import AboutPage from "../pages/about/AboutPage";
import ContactPage from "../pages/contact/ContactPage";
import ProgramsPage from "../pages/programs/ProgramsPage";
import ProgramApplyPage from "../pages/program-apply/ProgramApplyPage";
import ProgramsDetailPage from "../pages/program-detail/ProgramsDetailPage";
import SuccessStoriesPage from "../pages/success-stories/SuccessStoriesPage";
import PartnershipsPage from "../pages/partnerships/PartnershipsPage";
import PartnershipsContactPage from "../pages/partnerships-apply/PartnershipsContactPage";
import PrivacyPage from "../pages/privacy/PrivacyPage";

export interface PageProps {
  showPageWrapper: boolean;
}

const PageManager: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage showPageWrapper={true} />} />
      <Route path="*" element={<NotFoundPage showPageWrapper={false} />} />
      <Route path="/privacy" element={<PrivacyPage showPageWrapper={true} />} />
      <Route path="/about" element={<AboutPage showPageWrapper={true} />} />
      <Route
        path="/programs"
        element={<ProgramsPage showPageWrapper={true} />}
      />
      <Route
        path="/programs/apply"
        element={<ProgramApplyPage showPageWrapper={true} />}
      />
      <Route
        path="/programs/program-details"
        element={<ProgramsDetailPage showPageWrapper={true} />}
      />
      <Route
        path="/success-stories"
        element={<SuccessStoriesPage showPageWrapper={true} />}
      />
      <Route
        path="/partnerships"
        element={<PartnershipsPage showPageWrapper={true} />}
      />
      <Route
        path="/partnerships/contact"
        element={<PartnershipsContactPage showPageWrapper={true} />}
      />

      <Route path="/contact" element={<ContactPage showPageWrapper={true} />} />
      <Route
        path="/unsubscribe"
        element={<UnsubscribePage showPageWrapper={false} />}
      />

      <Route path="/" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default PageManager;
