import React, { useState } from "react";
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  useTheme,
  Typography,
  IconButton,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export const MenuItem = ({
  IconComponent = null,
  text,
  onClick,
  children = null,
  isSubMenu,
  indentOverride = null,
  hideCarrot = false,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleToggle = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const adjustedIndent = () => {
    if (indentOverride) {
      return indentOverride;
    }
    return isSubMenu ? theme.spacing(8) : theme.spacing(2);
  };

  return (
    <>
      <ListItem
        button
        onClick={onClick}
        disableRipple
        sx={{
          padding: "4px",
          paddingLeft: adjustedIndent(),
          paddingRight: "16px",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        {IconComponent && (
          <ListItemIcon sx={{ minWidth: "30px" }}>
            <Box sx={{ width: "24px", height: "24px" }}>
              <IconComponent
                sx={{
                  width: "24px",
                  height: "24px",
                  fill: theme.palette.colors.royalBlue[900],
                }}
              />
            </Box>
          </ListItemIcon>
        )}
        <ListItemText>
          <Typography fontWeight={isSubMenu ? 500 : 700}>{text}</Typography>
        </ListItemText>
        {children && !hideCarrot ? (
          <IconButton
            onClick={handleToggle}
            disableRipple
            sx={{ padding: "4px", opacity: 0.7 }}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        ) : null}
      </ListItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
};
