export interface ISuccessStory {
  title: string;
  subtitle: string;
  introduction: string;
  challenge: string;
  solution: string;
  results: string;
  name: SuccessStoryName;
  shortSummary: string;
  detailsUrl: string;
  image: string;
}

// export enum for name
export enum SuccessStoryName {
  RogueEngine = "Rogue Engine",
  InTheFootsteps = "In The Footsteps",
}

export const nameForSuccessStory = {
  "rogue-engine": SuccessStoryName.RogueEngine,
  "in-the-footsteps": SuccessStoryName.InTheFootsteps,
};
