import { Box } from "@mui/material";
import { HeaderTag } from "../../../ui-elements/HeaderTag";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Subtitle } from "../../../ui-elements/typography/Subtitle";
import { StackedImage } from "../blocks/StackedImage";
import { IProgram } from "../../../../types/data/IProgram";

interface ProgramDetailHeaderProps {
  program: IProgram;
}

export const ProgramDetailHeader = ({ program }: ProgramDetailHeaderProps) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      flexDirection={{ xs: "column-reverse", md: "row" }}
      alignItems={"center"}
      alignSelf={"center"}
      width={"100%"}
      maxWidth={"1000px"}
    >
      <Spacer height={"60px"} />
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        flexDirection={"column"}
        alignItems={{ xs: "center", md: "flex-start" }}
        justifyContent={{ xs: "flex-start", md: "center" }}
        paddingLeft={{ xs: "24px", sm: "40px", md: "60px" }}
        paddingRight={{ xs: "24px", sm: "40px", md: "60px" }}
      >
        <HeaderTag text={"How it Works"} />
        <Spacer height={"24px"} />
        <Header2 text={`What Multisynq™ Is:`} textAlign={"left"} />
        <Spacer height={"12px"} />
        <Subtitle
          text={`Multisynq™ is a decentralized multiplayer and collaboration platform for low-latency, real-time collaboration across applications and devices. It simplifies multiplayer networking complexities, enabling dynamic states for all users.`}
          textAlign={"left"}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={"100%"}
        marginBottom={{ xs: "24px", sm: "0px" }}
      >
        <StackedImage image={program.image} />
      </Box>
      <Spacer height={"60px"} />
    </Box>
  );
};
