import { Box, Typography } from "@mui/material";
import { Subtitle } from "../../../../ui-elements/typography/Subtitle";
import { Spacer } from "../../../../ui-elements/layout-elements/Spacer";
import { TextLink } from "../../../../ui-elements/TextLink";
import { Header4 } from "../../../../ui-elements/typography/Header4";
import { X } from "../../../../../icons";
import { IEvent } from "../events-section/eventData";

interface EventCellProps {
  event: IEvent;
}

export const EventCell: React.FC<EventCellProps> = ({ event }) => {
  const textAlign = "left";
  const imageSize = "100px";

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={{ xs: "center", md: "flex-start" }}
      justifyContent={"center"}
      width={"360px"}
      height={imageSize}
    >
      <Box
        height={imageSize}
        width={imageSize}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          borderRadius: "8px",
        }}
      >
        <img
          src={event.image}
          alt={"value"}
          height={imageSize}
          width={imageSize}
          style={{
            objectFit: "cover",
            borderRadius: "8px",
          }}
        />
      </Box>
      <Spacer width={"16px"} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        paddingRight={"16px"}
        paddingLeft={"8px"}
        marginTop={"-4px"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              width: "16px",
              height: "16px",
              cursor: "pointer",
              marginTop: "3px",
            }}
          >
            <X />
          </Box>
          <Typography marginTop={"4px"} marginLeft={"4px"} marginRight={"4px"}>
            |
          </Typography>
          <Header4 text={event.handle} textAlign={textAlign} />
        </Box>
        <Spacer height={"4px"} />
        <Subtitle
          text={event.text}
          textAlign={textAlign}
          sx={{
            fontSize: "0.9rem",
            overflow: "hidden",
            lineHeight: "1.15",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            opacity: 0.65,
          }}
        />
        <Spacer height={"8px"} />
        <TextLink
          text={"Read More"}
          textAlign="left"
          onClick={() => window.open(event.url, "_blank")}
          sx={{
            fontSize: "0.8rem",
          }}
        />
      </Box>
    </Box>
  );
};
