import { Divider } from "@mui/material";

export const MenuDivider = ({ hideTop = false, hideBottom = false }) => (
  <Divider
    sx={{
      marginTop: hideTop ? "0" : "10px",
      marginBottom: hideBottom ? "0" : "10px",
      opacity: 0.8,
    }}
  />
);
