import { Box, lighten, useTheme } from "@mui/material";
import { HeaderTag } from "../../../ui-elements/HeaderTag";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Body } from "../../../ui-elements/typography/Body";
import { Header4 } from "../../../ui-elements/typography/Header4";
import { CustomButton } from "../../../ui-elements/CustomButton";
import { useNavigate } from "react-router-dom";
import programs_header_img from "../../../../images/programs/programs-header.png";
import { Dots } from "../../../ui-elements/Dots";
import { DotsLocation } from "../../../../types/ui/dotsTypes";

export const ProgramsApplySection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const textColor = theme.palette.colors.blue[900];
  const textAlign = "left";

  const handleButtonClick = () => {
    navigate("/programs/apply");
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignSelf={"center"}
      width={"100%"}
      height={"100%"}
      maxWidth={"1000px"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        padding={{ xs: "24px", sm: "60px" }}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          width={"100%"}
          height={"300px"}
          sx={{
            backgroundImage: `url(${programs_header_img})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left",
            borderRadius: "8px",
          }}
        />
        <Spacer height={"48px"} />
        <HeaderTag text={"How it Works"} />
        <Spacer height={"24px"} />
        <Header2
          text={"Supporting Our Community through Grants and Funding."}
          textAlign={textAlign}
        />
        <Spacer height={"24px"} />
        <Body
          text={`The Multisynq Foundation is dedicated to empowering and supporting the Multisynq Network community. Our mission is to support the creators who push the boundaries of real-time, decentralized multiplayer experiences. Through grants, funding opportunities, and technical resources, we equip developers, educators, and enterprises to innovate freely by reducing constraints. Our goal is to foster a thriving ecosystem where creativity can flourish, and groundbreaking projects can come to life. <br><br> In addition to financial support, the Multisynq Foundation provides access to vital resources and expertise. We help participants navigate the complexities of multiplayer development by offering technical assistance, documentation, and other essential tools. Whether you're a university program building educational tools, an indie developer creating the next big game, or an enterprise seeking to revolutionize your collaborative processes, we are here to help you succeed in leveraging the potential of the Multisynq Network.`}
          textAlign={textAlign}
          sx={{
            color: lighten(textColor, 0.4),
          }}
        />
        <Spacer height={"24px"} />
        <Header4 text={"How to Apply"} textAlign={"left"} />
        <Spacer height={"12px"} />
        <Body
          text={`To apply for support from the Multisynq Foundation, we'll ask for details such as the project title, its current development stage, and the technology stack you're using. You'll also need to provide insights into your team's composition, describe any existing multiplayer features, and explain how our support could enhance your project. Once you have submitted all of the necessary information, our team will review your application and follow up with you on the next steps.`}
          textAlign={"left"}
          sx={{
            color: lighten(textColor, 0.4),
          }}
        />
        <Spacer height={"24px"} />
        <CustomButton onClick={() => handleButtonClick()}>
          Apply Now
        </CustomButton>
        <Dots dotsLocation={DotsLocation.right} />
      </Box>
    </Box>
  );
};
