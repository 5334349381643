import { FormConfig } from "../../../types/forms";

export const newsletterFormConfig: FormConfig = {
  title: "Newsletter Signup",
  fields: [
    {
      name: "submission_type",
      label: "",
      type: "hidden",
      value: "newsletter", // Hidden field with a predefined value
    },
    { name: "fullname", label: "Full name", type: "text", required: true },
    { name: "email", label: "Email", type: "email", required: true },
    {
      name: "agree_to_receive_communication",
      label: "I agree to receive communication from Multisynq Foundation",
      type: "checkbox",
    },
    {
      name: "agree_to_store_data",
      label: "I agree to allow Multisynq Foundation to store my personal data.",
      type: "checkbox",
    },
  ],
  submitButtonText: "Submit",
};
