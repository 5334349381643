import { Box } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import { ContactPageHeader } from "./sections/ContactPageHeader";
import { Spacer } from "../../ui-elements/layout-elements/Spacer";
import JoinSection from "../shared-sections/JoinSection";
import ContactForm from "../../forms/contact/ContactForm";
import { ContactsIntroSection } from "./sections/ContactIntroSection";

const ContactPage = ({ showPageWrapper }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
    window.scrollTo(0, 0);
  }, [dispatch, showPageWrapper]);

  return (
    <>
      <ContactPageHeader />
      <ContactsIntroSection />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        alignSelf={"center"}
        justifyContent="center"
        height={"100%"}
        width={"100%"}
        maxWidth={"1200px"}
        padding={"24px"}
      >
        <ContactForm />
      </Box>
      <Spacer height={"12px"} />
      <JoinSection />
    </>
  );
};

export default ContactPage;
