import React, { useState } from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { submitForm } from "../../../features/forms/formSubmissionSlice";
import { FormData, FormType } from "../../../types/forms";
import {
  FormSection,
  InputField,
  SubmitButton,
  ErrorMessage,
} from "../FormComponents";
import { partnershipFormConfig } from "./partnershipConfig";
import FormSubmissionFeedback from "../FormSubmissionFeedback";

const PartnershipForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormData>({});
  const formState = useAppSelector(
    (state) => state.formSubmission[FormType.PARTNERSHIP]
  );

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const target = e.target as
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement;
    const name = target.name;
    const value =
      target.type === "checkbox"
        ? (target as HTMLInputElement).checked
        : target.value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(submitForm({ formType: FormType.PARTNERSHIP, data: formData }));
  };

  if (formState?.isSubmitted) {
    return (
      <FormSubmissionFeedback
        title="Thank you for submitting your partnership request!"
        message="We will review your proposal and get back to you shortly."
      />
    );
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: "100%",
        maxWidth: "900px",
        margin: "auto",
        fontFamily: '"Be Vietnam Pro", sans-serif',
      }}
    >
      <FormSection title={partnershipFormConfig.title}>
        {partnershipFormConfig.fields.map((field) => (
          <InputField
            key={field.name}
            field={field}
            value={formData[field.name] || ""}
            onChange={handleInputChange}
          />
        ))}
      </FormSection>
      <SubmitButton
        text={partnershipFormConfig.submitButtonText}
        isProcessing={formState?.isProcessing || false}
      />
      {formState?.error && <ErrorMessage message={formState.error} />}
    </Box>
  );
};

export default PartnershipForm;
