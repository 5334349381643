import { Box, useTheme } from "@mui/material";
import { PageHeader } from "../../page-manager/PageHeader";
import { Header2 } from "../../ui-elements/typography/Header2";
import { Subtitle } from "../../ui-elements/typography/Subtitle";
import { IProgram } from "../../../types/data/IProgram";

interface ProgramApplyPageHeaderProps {
  program: IProgram;
}

export const ProgramApplyPageHeader: React.FC<ProgramApplyPageHeaderProps> = ({
  program,
}) => {
  const theme = useTheme();
  return (
    <PageHeader>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignSelf={"center"}
        paddingLeft={"24px"}
        paddingRight={"24px"}
        paddingTop={{ xs: "24px", sm: "0px" }}
        paddingBottom={{ xs: "24px", sm: "0px" }}
        width={"100%"}
        height={"100%"}
        maxWidth={"948px"}
      >
        <Header2
          text={`Apply for the ${program.pageTitle}`}
          textAlign="left"
          color={theme.palette.colors.grayScale.white}
          sx={{ marginBottom: "16px" }}
        />

        <Subtitle
          text={`${program.shortDescription}`}
          textAlign="left"
          color={theme.palette.colors.grayScale.white}
          sx={{
            opacity: 0.8,
          }}
        />
      </Box>
    </PageHeader>
  );
};
