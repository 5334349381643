import React, { useState } from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { submitForm } from "../../../features/forms/formSubmissionSlice";
import { FormData, FormType } from "../../../types/forms";
import {
  FormSection,
  InputField,
  SubmitButton,
  ErrorMessage,
} from "../FormComponents";
import { contactFormConfig } from "./contactFormConfig";
import FormSubmissionFeedback from "../FormSubmissionFeedback";

const ContactForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormData>({
    submission_type: "newsletter",
  });
  const formState = useAppSelector(
    (state) => state.formSubmission[FormType.CONTACT]
  );

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const target = e.target as
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement;
    const name = target.name;
    const value =
      target.type === "checkbox"
        ? (target as HTMLInputElement).checked
        : target.value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(submitForm({ formType: FormType.CONTACT, data: formData }));
  };

  if (formState?.isSubmitted) {
    return (
      <FormSubmissionFeedback
        title="Thank you for contacting us!"
        message="We will respond to your inquiry shortly."
      />
    );
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: "100%",
        maxWidth: "900px",
        margin: "auto",
        fontFamily: '"Be Vietnam Pro", sans-serif',
      }}
    >
      <FormSection title={contactFormConfig.title}>
        {contactFormConfig.fields.map((field) =>
          field.type !== "hidden" ? (
            <InputField
              key={field.name}
              field={field}
              value={formData[field.name] || ""}
              onChange={handleInputChange}
            />
          ) : null
        )}
      </FormSection>

      <SubmitButton
        text={contactFormConfig.submitButtonText}
        isProcessing={formState?.isProcessing || false}
      />

      {formState?.error && <ErrorMessage message={formState.error} />}
    </Box>
  );
};

export default ContactForm;
