import { Box, useMediaQuery, useTheme } from "@mui/material";
import { CustomDivider } from "../../../ui-elements/CustomDivider";
import { HeaderTag } from "../../../ui-elements/HeaderTag";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Subtitle } from "../../../ui-elements/typography/Subtitle";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { ValueCell } from "./cells/ValueCell";
import {
  Decentralization,
  FosteringGrowth,
  SupportingInnovation,
} from "../../../../icons";

const LandingAboutSection: React.FC = () => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      position={"relative"}
      top={0}
      bottom={0}
      left={0}
      right={0}
      justifyContent={"center"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        background: {
          xs: theme.palette.background.lightBackgroundGradient,
          md: "none",
        },
        paddingTop: { xs: "48px", md: "60px" },
      }}
    >
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        maxWidth={"1100px"}
        paddingLeft={"24px"}
        paddingRight={"24px"}
      >
        <HeaderTag text={"About Us"} />
        <Spacer height={"24px"} />
        <Header2 text={"About Multisynq Foundation"} />
        <Spacer height={"12px"} />
        <Subtitle
          text={`The Multisynq Foundation aims to foster the success of the Multisynq Network by supporting developers, hosting educational initiatives, and driving community engagement. Our mission is to decentralize infrastructure and promote innovation in collaborative applications.`}
          sx={{ maxWidth: "600px" }}
        />
        <Spacer height={isMobileSize ? "16px" : "70px"} />
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={"center"}
          alignItems={{ xs: "center", md: "flex-start" }}
          width={"100%"}
          height={"100%"}
          marginLeft={{ xs: "0px", md: "32px" }}
        >
          <ValueCell
            headerText={"Empowering Decentralization"}
            bodyText={`We believe in a decentralized future. By eliminating central points of failure, we create a robust and secure network that empowers users globally.`}
            icon={<Decentralization />}
          />
          <Spacer
            sx={{
              width: "60px",
            }}
          />
          <ValueCell
            headerText={"Supporting Innovation"}
            bodyText={`Innovation drives progress. We support cutting-edge research and development in synchronization technologies, ensuring our network stays ahead of the curve.`}
            icon={<SupportingInnovation />}
          />
          <Spacer
            sx={{
              width: "60px",
            }}
          />
          <ValueCell
            headerText={"Fostering Growth"}
            bodyText={`We are committed to nurturing the Multisynq ecosystem. Through grants, educational programs, and community initiatives, we foster growth and collaboration among developers and users.`}
            icon={<FosteringGrowth />}
          />
        </Box>
        <Spacer height={"60px"} />
        <CustomDivider sx={{ display: { xs: "none", sm: "flex" } }} />
      </Box>
    </Box>
  );
};

export default LandingAboutSection;
