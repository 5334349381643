import { Box, Button, Typography } from "@mui/material";
import foundation_logo_blue from "../../../images/foundation-logo-blue.png";
import { useNavigate } from "react-router-dom";

export const MobileHeader = ({ handleMenuClick, theme }) => {
  const navigate = useNavigate();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width="100%"
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        alignSelf={"center"}
        justifyContent={"center"}
        onClick={() => {
          navigate("/");
        }}
        sx={{
          width: "100%",
          paddingTop: "8px",
          marginRight: "16px",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <img src={foundation_logo_blue} alt="Logo" style={{ height: "60px" }} />
      </Box>
      <Button
        variant="contained"
        sx={{
          marginTop: "32px",
          marginBottom: "24px",
          fontSize: "1rem",
          width: "160px",
          height: "32px",
          backgroundColor: theme.palette.colors.royalBlue[500],
          boxShadow: "none",
          borderRadius: "16px",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: theme.palette.colors.blue[500],
          },
        }}
        onClick={() => handleMenuClick("contact")}
      >
        Contact Us
      </Button>
    </Box>
  );
};
