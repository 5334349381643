import { useEffect } from "react";
import { PageProps } from "../../page-manager/PageManager";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import { ProgramsHeaderSection } from "./sections/ProgramsHeaderSection";
import { ProgramsApplySection } from "./sections/ProgramsApplySection";
import ProgramsSection from "../shared-sections/ProgramsSection";
import JoinSection from "../shared-sections/JoinSection";
import { Box } from "@mui/material";
import { DotsLocation } from "../../../types/ui/dotsTypes";
import { Dots } from "../../ui-elements/Dots";

const ProgramsPage = ({ showPageWrapper }: PageProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"}>
      <ProgramsHeaderSection />
      <ProgramsApplySection />
      <ProgramsSection />
      <JoinSection />
    </Box>
  );
};

export default ProgramsPage;
