import rogueEngineImg from "../../../images/landing/RogueEngine.png";
import gaiminImg from "../../../images/landing/Gaimin.png";
import aethirImg from "../../../images/landing/Aethir.png";

export const partnershipsData = [
  {
    title: "Rogue Engine",
    text: "Rogue Engine, a Unity-like environment for creating web apps and games with @threejs has developed Rogue Croquet using the Multisynq Network for seamless serverless networking. Developers building with Rogue Engine have access to the Multisynq Network via a built-in API that allows the rapid development and time to market of games and applications at dramatically lower costs of development and operations.",
    image: rogueEngineImg,
  },
  {
    title: "Gaimin",
    text: "GAIMIN.io operates a decentralized GPU network that leverages idle computing power from distributed sources to provide scalable, cost-effective GPU processing for various applications, including AI, rendering, and scientific computing. This network enables users to rent or contribute GPU resources, fostering a more efficient and accessible infrastructure for high-performance computing tasks.",
    image: gaiminImg,
  },
  {
    title: "Aethir",
    text: "Aethir is a leading provider of decentralized hosting solutions, known for its robust infrastructure and commitment to innovation. Aethir empowers hosters with cutting-edge technology that maximizes their infrastructure investments and provides seamless services to their clients. By partnering with Multisynq, Aethir continues to push the boundaries of what’s possible in the hosting industry, offering unparalleled opportunities for growth and profitability.",
    image: aethirImg,
  },
];
