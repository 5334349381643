import { Box, lighten, useTheme, alpha } from "@mui/material";

const HtmlScrubberService = ({ html }: { html: string }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        color: theme.palette.common.white,
        backgroundColor: "transparent",
        "& p": {
          marginBottom: theme.spacing(3),
          fontSize: "1rem",
          opacity: 0.8,
          lineHeight: 1.6,
        },
        "& h1": {
          marginBottom: theme.spacing(3),
          marginTop: theme.spacing(4),
          fontSize: "2.5rem",
        },
        "& h2": {
          marginBottom: theme.spacing(2),
          marginTop: theme.spacing(3),
          fontSize: "2rem",
        },
        "& h3": {
          marginBottom: theme.spacing(1.5),
          marginTop: theme.spacing(2.5),
          fontSize: "1.75rem",
        },
        "& ul, & ol": {
          marginBottom: theme.spacing(2),
          paddingLeft: theme.spacing(4),
        },
        "& li": {
          marginBottom: theme.spacing(0.75),
        },
        "& a": {
          color: lighten(theme.palette.primary.main, 0.4),
          fontWeight: "bold",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
        "& hr": {
          borderColor: alpha(theme.palette.common.white, 0.2),
          marginBottom: theme.spacing(3),
        },
      }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default HtmlScrubberService;
