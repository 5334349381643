import { useEffect } from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/store";
import { setShowPageWrapper } from "../../features/ui/publicUiStateSlice";
import { PageProps } from "../page-manager/PageManager";
import logo_white_long from "../../images/logo-white.png";

const NotFoundPage = ({ showPageWrapper }: PageProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      position={"absolute"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      minHeight={"100vh"}
      sx={{ background: theme.palette.background.headerBackgroundGradient }}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignContent={"flex-start"}
        alignItems={"center"}
        margin={"24px"}
        marginBottom={"0px"}
      >
        <img
          src={logo_white_long}
          alt="Logo"
          style={{
            height: "40px",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/");
          }}
        />
      </Box>
      <Typography
        variant="h1"
        color={theme.palette.colors.babyBlue[500]}
        gutterBottom
      >
        404
      </Typography>
      <Typography
        variant="h5"
        paddingBottom={"24px"}
        gutterBottom
        color={theme.palette.colors.grayScale.white}
      >
        Page Not Found
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/")}
        sx={{
          mt: 2,
          // remove drop shadow
          boxShadow: "none",

          "&:hover": {
            backgroundColor: theme.palette.primary.light,
            boxShadow: "none",
          },
        }}
      >
        Go to Home
      </Button>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
      />
    </Box>
  );
};

export default NotFoundPage;
