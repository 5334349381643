import { Box, useMediaQuery, useTheme } from "@mui/material";
import logo_white from "../../../../images/logo-white.png";
import { rogue_engine } from "../../../../images/landing";
import { useNavigate } from "react-router-dom";
import { HeaderTag } from "../../../ui-elements/HeaderTag";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Subtitle } from "../../../ui-elements/typography/Subtitle";
import { TextLink } from "../../../ui-elements/TextLink";
import { CustomDivider } from "../../../ui-elements/CustomDivider";
import { DottedImageBlock } from "../../../ui-elements/image-elements/DottedFramedContentBlock";
import { successStoryData } from "../../success-stories/successStoryData";
import { ISuccessStory } from "../../../../types/data/ISuccesStory";
import { Dots } from "../../../ui-elements/Dots";
import { DotsLocation } from "../../../../types/ui/dotsTypes";

export const LandingSuccessSection = () => {
  const theme = useTheme();

  const isMobileSize = useMediaQuery(theme.breakpoints.down("md"));
  const textAlign = isMobileSize ? "center" : "left";
  const contentAlign = isMobileSize ? "center" : "flex-start";

  const handleReadMoreClicked = (successStory: ISuccessStory) => {
    console.log("Read More Clicked for ", successStory.detailsUrl);
    window.location.href = successStory.detailsUrl;
  };

  const DetailsBlock = ({ header, body, image, onClick }) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={contentAlign}
      >
        <Spacer height={"24px"} />
        <Header2 text={header} textAlign={textAlign} />
        <Spacer height={"12px"} />
        <Subtitle
          text={body}
          textAlign={textAlign}
          sx={{ maxWidth: "500px" }}
        />
        <Spacer height={"16px"} />
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={contentAlign}
          justifyContent={contentAlign}
          width={"100%"}
        >
          <TextLink
            text={"Read More"}
            onClick={onClick}
            sx={{
              textAlign: textAlign,
              fontWeight: 600,
            }}
          />
        </Box>
      </Box>
    );
  };

  const Row = ({ header, body, image, onClick, reversed = false }) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        maxWidth={"900px"}
        sx={{
          flexDirection: {
            xs: "column",
            md: reversed ? "row-reverse" : "row",
          },
        }}
      >
        <DottedImageBlock height="360px" width="360px" forceSize={true}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
          >
            <img
              src={image}
              alt={"Success Story"}
              style={{
                height: image === rogue_engine ? "100px" : "60px",
                width: "auto",
                objectFit: "cover",
                alignSelf: "center",
                justifySelf: "center",
                filter: "brightness(0) invert(1)",
              }}
            />
          </Box>
        </DottedImageBlock>
        <Spacer width={"350px"} />
        <DetailsBlock
          header={header}
          body={body}
          image={image}
          onClick={onClick}
        />
      </Box>
    );
  };

  return (
    <Box
      position={"relative"}
      top={0}
      bottom={0}
      left={0}
      right={0}
      justifyContent={"center"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        background: {
          xs: theme.palette.background.lightBackgroundGradient,
          md: "none",
        },
      }}
    >
      <CustomDivider
        sx={{
          marginLeft: "24px",
          marginRight: "24px",
          width: "calc(100% - 48px)",
        }}
      />
      <Spacer height={"60px"} />
      <HeaderTag text={"Success Stories"} />
      <Spacer height={"60px"} />
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        paddingLeft={"24px"}
        paddingRight={"24px"}
      >
        <Row
          header={successStoryData[0].name}
          body={successStoryData[0].shortSummary}
          image={rogue_engine}
          onClick={() => handleReadMoreClicked(successStoryData[0])}
        />
        <Spacer height={"100px"} />
        <Row
          header={successStoryData[1].name}
          body={successStoryData[1].shortSummary}
          image={logo_white}
          onClick={() => handleReadMoreClicked(successStoryData[1])}
          reversed
        />
        <Spacer height={"60px"} />
        <Dots dotsLocation={DotsLocation.right} />
      </Box>
    </Box>
  );
};
