import { useEffect } from "react";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import { Box } from "@mui/material";
import { ProgramApplyPageHeader } from "./ProgramApplyPageHeader";
import { useLocationToGetProgram } from "../../helpers/useLocationToGetProgram";
import ProgramApplicationForm from "../../forms/program-application/ProgramAppicationForm";
import { Spacer } from "../../ui-elements/layout-elements/Spacer";
import { programs } from "../programs/programData";

const ProgramApplyPage = ({ showPageWrapper }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
    window.scrollTo(0, 0);
  }, [dispatch, showPageWrapper]);

  let activeProgram = useLocationToGetProgram();

  if (!activeProgram) {
    activeProgram = programs[0];
  }

  return (
    <>
      <ProgramApplyPageHeader program={activeProgram} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        alignSelf={"center"}
        marginTop={"30px"}
        justifyContent="center"
        height={"100%"}
        width={"100%"}
        maxWidth={"1200px"}
        paddingLeft={"24px"}
        paddingRight={"24px"}
      >
        <ProgramApplicationForm programName={activeProgram.pageTitle} />
      </Box>
      <Spacer height={"60px"} />
    </>
  );
};

export default ProgramApplyPage;
