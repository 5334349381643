import { Box, lighten, useTheme } from "@mui/material";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Body } from "../../../ui-elements/typography/Body";
import { ISuccessStory } from "../../../../types/data/ISuccesStory";
import { Header3 } from "../../../ui-elements/typography/Header3";

interface SuccessStoriesBodySectionProps {
  activeSuccessStory: ISuccessStory;
}

export const SuccessStoriesBodySection = ({
  activeSuccessStory,
}: SuccessStoriesBodySectionProps) => {
  const theme = useTheme();
  const textColor = theme.palette.colors.blue[900];
  const textAlign = "left";

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignSelf="center"
      width="100%"
      height="100%"
      maxWidth="1000px"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        padding={{ xs: "24px", sm: "60px" }}
      >
        <Header2 text={activeSuccessStory.title} textAlign={textAlign} />
        <Header3
          text={activeSuccessStory.subtitle}
          textAlign={textAlign}
          sx={{
            fontWeight: "700px",
          }}
        />
        <Spacer height="24px" />
        <Body
          text={activeSuccessStory.shortSummary}
          textAlign={textAlign}
          sx={{ color: lighten(textColor, 0.4) }}
        />
        <Spacer height="48px" />
        <Box
          component="img"
          src={activeSuccessStory.image}
          alt={activeSuccessStory.title}
          sx={{
            width: "100%",
            height: "auto",
            borderRadius: "8px",
          }}
        />
        <Spacer height="48px" />
        <Header3 text="Introduction" textAlign={textAlign} />
        <Spacer height="12px" />
        <Body
          text={activeSuccessStory.introduction}
          textAlign={textAlign}
          sx={{ color: lighten(textColor, 0.4) }}
        />
        <Spacer height="24px" />
        <Header3 text="Challenge" textAlign={textAlign} />
        <Spacer height="12px" />
        <Body
          text={activeSuccessStory.challenge}
          textAlign={textAlign}
          sx={{ color: lighten(textColor, 0.4) }}
        />
        <Spacer height="24px" />
        <Header3 text="Solution" textAlign={textAlign} />
        <Spacer height="12px" />
        <Body
          text={activeSuccessStory.solution}
          textAlign={textAlign}
          sx={{ color: lighten(textColor, 0.4) }}
        />
        <Spacer height="24px" />
        <Header3 text="Results" textAlign={textAlign} />
        <Spacer height="12px" />
        <Body
          text={activeSuccessStory.results}
          textAlign={textAlign}
          sx={{ color: lighten(textColor, 0.4) }}
        />
      </Box>
    </Box>
  );
};
