import { Box, useMediaQuery, useTheme } from "@mui/material";
import guitar_picks from "../../../../images/landing/guitar-picks.png";
import CleanSVG from "../../../../services/CleanSVG";
import { SvgName } from "../../../../images/SvgAssets";
import { useNavigate } from "react-router-dom";
import { HeaderTag } from "../../../ui-elements/HeaderTag";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { Subtitle } from "../../../ui-elements/typography/Subtitle";
import { CustomButton } from "../../../ui-elements/CustomButton";
import { Header1 } from "../../../ui-elements/typography/Header1";

export const LandingHeaderSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const textColor = theme.palette.colors.blue[900];

  const handleButtonClick = (buttonName: string) => {
    switch (buttonName) {
      case "Get Involved":
        navigate("/programs");
        break;
      case "Learn More":
        navigate("/about");
        break;
      default:
        break;
    }
  };

  const isLessThanSm = useMediaQuery(theme.breakpoints.down("md"));
  function getHeight() {
    if (isLessThanSm) {
      return "200px";
    }
    return "550px";
  }

  const isMobileSize = useMediaQuery(theme.breakpoints.down("md"));
  const textAlign = isMobileSize ? "center" : "left";
  const contentAlign = isMobileSize ? "center" : "flex-start";

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      flexDirection={{ xs: "column-reverse", md: "row" }}
      alignItems={"center"}
      alignSelf={"center"}
      width={"100%"}
      maxWidth={"1200px"}
      paddingLeft={{ xs: "24px", sm: "0px" }}
      paddingRight={{ xs: "24px", sm: "0px" }}
      height={{ xs: "none", md: "600px" }}
    >
      <Spacer height={"60px"} />
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        flexDirection={"column"}
        alignItems={{ xs: "center", md: "flex-start" }}
        justifyContent={{ xs: "flex-start", md: "center" }}
        marginLeft={{ xs: "0px", md: "60px" }}
        padding={{ xs: "24px", lg: "0px" }}
      >
        <HeaderTag text={"Fostering Growth"} />
        <Spacer height={"24px"} />
        <Header1
          text={"Empowering The Future in Decentralized Collaboration"}
          textAlign={textAlign}
        />
        <Spacer height={"12px"} />
        <Subtitle
          text={`The Multisynq Foundation is a non-profit organization dedicated to the innovation, growth, and adoption of the Multisynq Network through various developer and community programs.`}
          textAlign={textAlign}
        />
        <Spacer height={"32px"} />
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={contentAlign}
          justifyContent={contentAlign}
          width={"100%"}
        >
          <CustomButton onClick={() => handleButtonClick("Get Involved")}>
            Get Involved
          </CustomButton>
          <Spacer width={"16px"} />
          <CustomButton onClick={() => handleButtonClick("Learn More")}>
            Learn More
          </CustomButton>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={"100%"}
        marginTop={"40px"}
      >
        <Box
          sx={{
            transform: { xs: "scale(1.2)", md: "scale(1.0)" },
          }}
        >
          <img src={guitar_picks} alt="Logo" style={{ height: getHeight() }} />
        </Box>
      </Box>
      <Box
        display={{ xs: "flex", md: "flex" }}
        position={"absolute"}
        top={{ xs: "0px", md: "60px" }}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        height={"600px"}
        sx={{
          paddingTop: "60px",
          marginTop: { xs: "-120px", sm: "-40px", md: "0px" },
          zIndex: -1,
        }}
      >
        <CleanSVG
          name={SvgName.MapDots}
          style={{
            width: "95%",
            height: "95%",
            opacity: 0.7,
          }}
        />
      </Box>
      <Spacer height={"60px"} />
    </Box>
  );
};
