import React from "react";
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  useTheme,
  InputAdornment,
} from "@mui/material";
import { lighten } from "@mui/material/styles";
import { FormField, FormFieldType, SelectFormField } from "../../types/forms";
import { CalendarTodayOutlined } from "@mui/icons-material";

// Universal color fetcher
const useThemeColor = () => {
  const theme = useTheme();
  return {
    primary: theme.palette.primary.main,
    error: theme.palette.colors.red[500],
    placeholder: theme.palette.colors.babyBlue[500],
  };
};

// Constants
const LABEL_FONT_SIZE = "0.875rem";
const BORDER_RADIUS = "12px";

const inputStyles = {
  "& .MuiInputBase-input": {
    padding: "16.5px 14px",
  },
  "& .MuiInputLabel-root": {
    fontSize: LABEL_FONT_SIZE,
    "&.MuiInputLabel-shrink": {
      background: "white",
      padding: "0 5px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: BORDER_RADIUS,
    "& fieldset": {
      borderRadius: BORDER_RADIUS,
    },
  },
};

// FormSection component
export const FormSection: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => (
  <Box sx={{ marginBottom: "20px" }}>
    <Typography variant="h2" sx={{ fontSize: "24px", marginBottom: "15px" }}>
      {title}
    </Typography>
    {children}
  </Box>
);

// InputField component
export const InputField: React.FC<{
  field: FormField;
  value: string | boolean;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
      | { target: { name: string; value: string } }
  ) => void;
}> = ({ field, value, onChange }) => {
  const themeColors = useThemeColor();

  switch (field.type) {
    case "select":
      return (
        <FormControl fullWidth sx={{ marginBottom: "15px" }}>
          <InputLabel
            id={`${field.name}-label`}
            sx={{
              fontSize: LABEL_FONT_SIZE,
              backgroundColor: "white",
              padding: "0 5px",
              "&.Mui-focused": {
                backgroundColor: "white",
              },
              "&.MuiInputLabel-shrink": {
                backgroundColor: "white",
              },
            }}
          >
            {field.label}
          </InputLabel>
          <Select
            labelId={`${field.name}-label`}
            id={field.name}
            name={field.name}
            value={value as string}
            onChange={onChange as (event: SelectChangeEvent<string>) => void}
            required={field.required}
            label={field.label}
            sx={{
              ...inputStyles,
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: BORDER_RADIUS,
              },
            }}
          >
            {(field as SelectFormField).options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "checkbox":
      return (
        <FormControlLabel
          control={
            <Checkbox
              name={field.name}
              checked={Boolean(value)}
              onChange={(e) =>
                onChange({
                  target: {
                    name: field.name,
                    value: e.target.checked.toString(),
                  },
                })
              }
              required={field.required}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            />
          }
          label={field.label}
          sx={{
            marginBottom: "15px",
            ".MuiFormControlLabel-label": {
              fontSize: LABEL_FONT_SIZE,
              paddingTop: "2px",
            },
          }}
        />
      );
    case "date":
      return (
        <TextField
          fullWidth
          type="date"
          id={field.name}
          name={field.name}
          value={value as string}
          onChange={onChange}
          required={field.required}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CalendarTodayOutlined fontSize="small" />
              </InputAdornment>
            ),
          }}
          sx={{
            ...inputStyles,
            marginBottom: "15px",
            "& .MuiInputBase-root": {
              height: "40px",
            },
            "& .MuiInputLabel-root": {
              transform: "translate(14px, -9px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              padding: "8px 14px 8px 0",
            },
          }}
        />
      );
    case "textarea":
      return (
        <TextField
          fullWidth
          multiline
          rows={5}
          id={field.name}
          name={field.name}
          label={field.label}
          value={value as string}
          onChange={onChange}
          required={field.required}
          sx={{
            ...inputStyles,
            marginBottom: "15px",
          }}
        />
      );

    default:
      return (
        <TextField
          fullWidth
          type={field.type as FormFieldType}
          id={field.name}
          name={field.name}
          label={field.label}
          value={value as string}
          onChange={onChange}
          required={field.required}
          sx={{
            ...inputStyles,
            marginBottom: "15px",
          }}
        />
      );
  }
};

// SubmitButton component
export const SubmitButton: React.FC<{
  text: string;
  isProcessing: boolean;
  disabled?: boolean;
  onClick?: () => void;
}> = ({ text, isProcessing, disabled = false, onClick }) => {
  const themeColors = useThemeColor();

  return (
    <Button
      type="submit"
      onClick={onClick}
      disabled={disabled || isProcessing}
      sx={{
        backgroundColor: themeColors.primary,
        color: "white",
        maxHeight: "40px",
        fontWeight: 600,
        padding: "12px 24px",
        borderRadius: BORDER_RADIUS,
        "&:hover": {
          backgroundColor: lighten(themeColors.primary, 0.1),
        },
        "&:disabled": {
          backgroundColor: lighten(themeColors.primary, 0.3),
        },
      }}
    >
      {isProcessing ? "Submitting..." : text}
    </Button>
  );
};

// ErrorMessage component
export const ErrorMessage: React.FC<{
  message: string;
}> = ({ message }) => {
  const themeColors = useThemeColor();

  return (
    <Typography
      sx={{
        color: themeColors.error,
        marginTop: "10px",
        fontSize: LABEL_FONT_SIZE,
      }}
    >
      {message}
    </Typography>
  );
};
