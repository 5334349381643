import { Box, Drawer, IconButton, List, useTheme } from "@mui/material";
import { Logo, Close, Programs, Partners } from "../../../icons";
import { MenuItem } from "./MenuItem";
import DrawerTermsFooter from "./DrawerTermsFooter";
import { MenuDivider } from "./MenuDivider";
import { MobileHeader } from "./MobileHeader";

export const MobileMenuDrawer = ({
  handleMenuClick,
  setDrawerOpen,
  drawerOpen,
}) => {
  const theme = useTheme();
  const handleClick = (viewName: string, forceNavigate = false) => {
    setDrawerOpen(false);
    handleMenuClick(viewName, forceNavigate);
  };

  const menuItems = (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        marginTop: "8px",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <List sx={{ width: "100%" }}>
        <MenuDivider hideTop />
        <MenuItem
          IconComponent={Logo}
          text="About Us"
          onClick={() => handleClick("about")}
          isSubMenu={false}
        />
        <MenuDivider />
        <MenuItem
          IconComponent={Programs}
          text="Programs"
          onClick={() => handleClick("programs")}
          isSubMenu={false}
        />
        <MenuDivider />
        <MenuItem
          IconComponent={Partners}
          text="Partnerships"
          onClick={() => handleClick("partnerships")}
          isSubMenu={false}
        />
        <MenuDivider />
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          maxWidth: { xs: "100%", sm: "400px" },
          paddingTop: "8px",
        },
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
        sx={{ width: "100%", height: "40px", mt: "10px" }}
      >
        {<Box display={{ xs: "none", sm: "block" }} width={"30px"} />}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            width: "100%",
            height: "auto",
            marginLeft: "16px",
          }}
        />
        <IconButton
          aria-label="close drawer"
          edge="end"
          onClick={() => setDrawerOpen(false)}
          size="small"
          sx={{
            height: "30px",
            width: "30px",
            marginTop: "5px",
            marginRight: "23px",
          }}
        >
          <Close
            sx={{
              fill: theme.palette.colors.royalBlue[900],
              width: "20px",
              height: "20px",
            }}
          />
        </IconButton>
      </Box>
      <MobileHeader handleMenuClick={handleMenuClick} theme={theme} />
      {menuItems}
      <DrawerTermsFooter />
    </Drawer>
  );
};
