import React, { useState } from "react";
import {
  alpha,
  Box,
  Button,
  lighten,
  TextField,
  useTheme,
} from "@mui/material";

interface NewsletterInputFieldProps {
  onEmailChange: (email: string) => void;
  onSubscribe: () => void;
}

const NewsletterInputField: React.FC<NewsletterInputFieldProps> = ({
  onEmailChange,
  onSubscribe,
}) => {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState("");

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    onEmailChange(newEmail);
    setError("");
  };

  const handleSubmit = () => {
    if (validateEmail(email)) {
      console.log(`Email submitted: ${email}`);
      onSubscribe();
      setError("");
    } else {
      setError("Please enter a valid email address");
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const getBackgroundColor = () => {
    if (isFocused) return theme.palette.colors.grayScale.white;
    if (isHovered) return lighten(theme.palette.colors.royalBlue[100], 0.75);
    return lighten(theme.palette.colors.royalBlue[100], 0.5);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "relative",
          background: getBackgroundColor(),
          borderRadius: "40px",
          overflow: "hidden",
          width: { xs: "100%", md: "500px" },
          height: "50px",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Enter Email Address"
          value={email}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          error={!!error}
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            right: "88px",
            "& .MuiOutlinedInput-root": {
              height: "100%",
              "& fieldset": {
                border: "none",
              },
              "& input": {
                padding: "0 24px",
                paddingRight: "92px",
                color: theme.palette.text.primary,
                backgroundColor: "transparent",
                fontSize: "1.0rem",
                "::placeholder": {
                  color: theme.palette.colors.blue[900],
                  opacity: 0.4,
                },
              },
            },
          }}
          fullWidth
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={email === ""}
          sx={{
            position: "absolute",
            fontSize: "1.0rem",
            right: 0,
            height: `calc(100% - 6px)`,
            borderRadius: "40px",
            backgroundColor: theme.palette.colors.royalBlue[500],
            color: theme.palette.colors.grayScale.white,
            margin: "3px",
            width: "110px",
            "&:hover": {
              backgroundColor: lighten(
                theme.palette.colors.royalBlue[500],
                0.1
              ),
            },
          }}
        >
          Subscribe
        </Button>
      </Box>
      {error && (
        <Box
          sx={{
            color: theme.palette.error.main,
            fontSize: "0.75rem",
            marginTop: "4px",
          }}
        >
          {error}
        </Box>
      )}
    </Box>
  );
};

export default NewsletterInputField;
