import { Box } from "@mui/material";

export const CustomDivider = ({ sx = null }) => {
  return (
    <Box
      display={"flex"}
      width={"100%"}
      height={"1px"}
      sx={{
        borderBottom: "1px solid black",
        opacity: 0.1,
        ...sx,
      }}
    />
  );
};
