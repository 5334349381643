import { alpha, Typography, useTheme } from "@mui/material";

interface TextLinkProps {
  text: string;
  onClick: () => void;
  textAlign?: "center" | "left" | "right";
  sx?: any;
}

export const TextLink: React.FC<TextLinkProps> = ({
  text = "placeholder",
  onClick,
  textAlign = "center",
  sx = null,
}) => {
  const theme = useTheme();

  return (
    <Typography
      fontSize={"0.9rem"}
      textAlign={textAlign}
      width={"100%"}
      onClick={onClick}
      sx={{
        color: theme.palette.colors.blue[500],
        "&:hover": {
          opacity: 0.8,
          cursor: "pointer",
        },
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};
