import { Box, useTheme } from "@mui/material";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Subtitle } from "../../../ui-elements/typography/Subtitle";
import { PageHeader } from "../../../page-manager/PageHeader";

export const PartnershipsHeaderSection: React.FC = () => {
  const theme = useTheme();
  return (
    <PageHeader>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignSelf={"center"}
        paddingLeft={{ xs: "24px", sm: "60px" }}
        paddingRight={{ xs: "24px", sm: "60px" }}
        paddingTop={{ xs: "24px", sm: "0px" }}
        paddingBottom={{ xs: "24px", sm: "0px" }}
        width={"100%"}
        height={"100%"}
        maxWidth={"1000px"}
      >
        <Header2
          text="Multisynq Programs: Grants and Funding"
          textAlign="left"
          color={theme.palette.colors.grayScale.white}
          sx={{ marginBottom: "16px" }}
        />

        <Subtitle
          text={`Multisynq partners with a wide range of companies across multiple sectors, including Game Developers (indie developers and studios), Web developers, Enterprises, Universities. Lower Education and Infrastructure Hosts of all kinds to eliminate the need for complex servers and netcode, making it easier and more cost-effective for developers to build and operate multiplayer experiences and even earn revenues from excess infrastructure.`}
          textAlign="left"
          color={theme.palette.colors.grayScale.white}
          sx={{
            opacity: 0.8,
          }}
        />
      </Box>
    </PageHeader>
  );
};
