import { Box, useTheme } from "@mui/material";
import { LandingHeaderSection } from "./sections/LandingHeaderSection";
import ConfirmationPopup from "../../popups/ConfirmationPopup";
import { PageProps } from "../../page-manager/PageManager";
import { useAppDispatch } from "../../../app/store";
import { setShowPageWrapper } from "../../../features/ui/publicUiStateSlice";
import { useEffect } from "react";
import LandingAboutSection from "./sections/LandingAboutSection";
import PartnerSection from "../shared-sections/LandingPartnerSection";
import ProgramsSection from "../shared-sections/ProgramsSection";
import { LandingSuccessSection } from "./sections/LandingSuccessSection";
import JoinSection from "../shared-sections/JoinSection";
import LandingEventsSection from "./sections/LandingEventsSection";
import LandingCommunitySection from "./sections/community-section/LandingCommunitySection";
import LandingNewsletterSection from "./sections/LandingNewsletterSection";

function LandingPage({ showPageWrapper }: PageProps) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setShowPageWrapper(showPageWrapper));
  }, []);
  const theme = useTheme();
  return (
    <Box
      flexDirection={"column"}
      display="flex"
      right={0}
      left={0}
      top={0}
      bottom={0}
      sx={{
        overflowX: "hidden",
      }}
    >
      <ConfirmationPopup />
      <LandingHeaderSection />
      <LandingAboutSection />
      <PartnerSection />
      <ProgramsSection />
      <LandingSuccessSection />
      <JoinSection />
      <LandingEventsSection />
      {/* <LandingCommunitySection /> */}
      <LandingNewsletterSection />
    </Box>
  );
}

export default LandingPage;
