import React, { useEffect, useState } from "react";
import { Box, lighten, useTheme } from "@mui/material";
import { CustomButton } from "../../ui-elements/CustomButton";
import { Header2 } from "../../ui-elements/typography/Header2";
import { Subtitle } from "../../ui-elements/typography/Subtitle";
import { Spacer } from "../../ui-elements/layout-elements/Spacer";
import CleanSVG from "../../../services/CleanSVG";
import { SvgName } from "../../../images/SvgAssets";
import { alpha } from "@mui/material/styles";
import "./Wiggle.css"; // Ensure the path is correct
import { useNavigate } from "react-router-dom";
import NewsletterPopup from "../../popups/newsletter/NewsletterPopup";

const JoinSection: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [hovering, setHovering] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State for popup visibility

  useEffect(() => {
    //console.log("LandingJoinSection mounted");
  }, [hovering, isPopupOpen]);

  const handleButtonClick = (buttonName: string) => {
    if (buttonName === "Join The Community") {
      window.open("https://multisynq.io/discord", "_blank");
    }

    if (buttonName === "Stay Updated") {
      setIsPopupOpen(true);
    }
  };

  const handlePopupDismiss = () => {
    setIsPopupOpen(false);
  };

  return (
    <Box
      position="relative"
      top={0}
      bottom={0}
      left={0}
      right={0}
      justifyContent="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        background: theme.palette.background.mediumBackgroundGradient,
        paddingTop: "60px",
        paddingBottom: "60px",
      }}
      overflow="hidden"
    >
      <Box
        display="flex"
        height="100%"
        width="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxWidth="1200px"
        paddingLeft="24px"
        paddingRight="24px"
        overflow="hidden"
      >
        <Box
          position="absolute"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="600px"
          sx={{
            zIndex: 0,
          }}
        >
          <CleanSVG
            name={SvgName.WorldDotsWhite}
            style={{
              width: "95%",
              height: "95%",
              color: theme.palette.colors.grayScale.white,
              fill: alpha(theme.palette.colors.grayScale.white, 0.7),
              opacity: 0.7,
            }}
          />
        </Box>
        <Spacer height="24px" />
        <Header2
          text="Join the Multisynq Community"
          color={theme.palette.colors.grayScale.white}
        />
        <Spacer height="12px" />
        <Subtitle
          text="Be a part of the decentralized future. Apply now to our programs or join our community to stay updated."
          color={theme.palette.colors.grayScale.white}
          sx={{ maxWidth: "500px" }}
        />
        <Spacer height="48px" />
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <CustomButton
            onClick={() => handleButtonClick("Join The Community")}
            setHovering={setHovering}
            highlightColorOverride={lighten(
              theme.palette.colors.royalBlue[100],
              0.7
            )}
            sx={{
              backgroundColor: theme.palette.colors.grayScale.white,
              color: theme.palette.colors.royalBlue[900],
            }}
          >
            <Box
              marginRight={"4px"}
              sx={{
                animation: hovering ? "wiggle 1.3s infinite" : "none",
              }}
            >
              <CleanSVG
                name={SvgName.DiscordLogo}
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </Box>
            Join Community
          </CustomButton>
          <Spacer width="16px" />
          <CustomButton onClick={() => handleButtonClick("Stay Updated")}>
            Stay Updated
          </CustomButton>
        </Box>
        <Spacer height="32px" />
      </Box>
      <NewsletterPopup
        triggerPopup={isPopupOpen}
        onDismiss={handlePopupDismiss}
      />
    </Box>
  );
};

export default JoinSection;
