import { Box, lighten, useTheme } from "@mui/material";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import { Body } from "../../../ui-elements/typography/Body";
import { useNavigate } from "react-router-dom";
import { Header3 } from "../../../ui-elements/typography/Header3";
import { CustomButton } from "../../../ui-elements/CustomButton";

export const PartnershipsApplySection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const textColor = theme.palette.colors.blue[900];
  const textAlign = "left";

  const handleButtonClick = () => {
    navigate("/partnerships/contact");
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignSelf={"center"}
      width={"100%"}
      height={"100%"}
      maxWidth={"1000px"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        paddingLeft={{ xs: "24px", md: "60px" }}
        paddingRight={{ xs: "24px", md: "60px" }}
      >
        <Header3 text={"Benefits of Partnership"} textAlign={textAlign} />
        <Spacer height={"12px"} />
        <Body
          text={`Companies partner with Multisynq for several key reasons:<br /><ol style="padding-left: 30px;"><li style="padding-bottom: 10px;"><strong>Innovative Monetization:</strong> The DePIN model and $SYNQ tokens provide a sustainable economic model and revenue opportunities​​.</li><li style="padding-bottom: 10px;"><strong>Cost Efficiency:</strong> Multisynq's decentralized infrastructure reduces server costs by over 80%, with affordable pricing at $25 per terabyte​​.</li><li style="padding-bottom: 10px;"><strong>Low Latency:</strong> It offers ultra-low latency (15-30ms) through a global decentralized network, essential for real-time applications like gaming and AR/VR​​.</li><li style="padding-bottom: 10px;"><strong>Simplified Development:</strong> Multisynq eliminates the need for custom netcode, speeding up time to market and reducing development costs​​.</li><li style="padding-bottom: 10px;"><strong>Scalability:</strong> The platform automatically scales with demand, ensuring consistent performance across regions​.</li><li style="padding-bottom: 10px;"><strong>Security:</strong> End-to-end encryption and reliable failover mechanisms enhance data security and system reliability​.</li></ol>These factors make Multisynq an attractive partner for companies looking to enhance their real-time, multi-user experiences while optimizing costs and development processes.`}
          textAlign={textAlign}
          sx={{
            color: lighten(textColor, 0.4),
          }}
        />
        <Spacer height={"60px"} />
        <CustomButton onClick={() => handleButtonClick()}>
          Connect With Us
        </CustomButton>
        <Spacer height={"60px"} />
      </Box>
    </Box>
  );
};
