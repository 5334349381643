import { Typography, useTheme } from "@mui/material";

interface Header4Props {
  text: string;
  textAlign?: "center" | "left" | "right";
  color?: string;
  sx?: any;
}

export const Header4: React.FC<Header4Props> = ({
  text = "placeholder",
  textAlign = "center",
  color = null,
  sx = null,
}) => {
  const theme = useTheme();
  const colorValue = theme.palette.text.primary;

  return (
    <Typography
      fontSize={"1.2rem"}
      fontWeight={"700"}
      textAlign={textAlign}
      width={"100%"}
      sx={{
        color: color ? color : colorValue,
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};
