export interface IProgram {
  offerings: string[];
  benefits: string[];
  name: string;
  image: string;
  url: string;
  pageTitle: string;
  pageSummary: string;
  pageIntro: string;
  programSummary: string;
  shortDescription: string;
  icon: React.ReactNode;
  howToQualify: string[];
  applicationUrl: string;
  hubspotProgramName: HubspotProgramName;
}

export enum ProgramName {
  IndieDev = "indie-dev-program",
  Enterprise = "enterprise-program",
  University = "university-program",
  Educators = "educators-program",
}

export enum HubspotProgramName {
  IndieDev = "Indi Dev Program",
  Enterprise = "Enterprise Program",
  University = "University Program",
  Educators = "Educators Program",
}
