// src/types/forms.ts

export type FormFieldType =
  | "text"
  | "email"
  | "url"
  | "textarea"
  | "select"
  | "checkbox"
  | "date"
  | "hidden";

export interface BaseFormField {
  name: string;
  label: string;
  type: FormFieldType;
  required?: boolean;
}

export interface TextFormField extends BaseFormField {
  type: "text" | "email" | "url" | "date";
}

export interface TextareaFormField extends BaseFormField {
  type: "textarea";
}

export interface SelectFormField extends BaseFormField {
  type: "select";
  options: string[];
}

export interface CheckboxFormField extends BaseFormField {
  type: "checkbox";
}

export interface HiddenFormField extends BaseFormField {
  type: "hidden";
  value: string;
}

export type FormField =
  | TextFormField
  | TextareaFormField
  | SelectFormField
  | CheckboxFormField
  | HiddenFormField;

export interface FormConfig {
  title: string;
  fields: FormField[];
  submitButtonText: string;
}

export interface FormData {
  [key: string]: string | boolean;
}

// Update the FormType enum in src/types/forms.ts
export enum FormType {
  NEWSLETTER = "newsletter",
  PROGRAM_APPLICATION = "program_application",
  CONTACT = "contact",
  PARTNERSHIP = "partnership",
}

export interface FormState {
  isProcessing: boolean;
  isSubmitted: boolean;
  error: string | null;
}

export type FormSubmissionState = {
  [K in FormType]?: FormState;
};
