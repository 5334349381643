import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Header2 } from "../../../ui-elements/typography/Header2";
import { Subtitle } from "../../../ui-elements/typography/Subtitle";
import { Spacer } from "../../../ui-elements/layout-elements/Spacer";
import NewsletterInputField from "../../../ui-elements/NewsletterInputField";
import NewsletterPopup from "../../../popups/newsletter/NewsletterPopup";

const LandingNewsletterSection: React.FC = () => {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleEmailChange = (newEmail: string) => {
    setEmail(newEmail);
  };

  const handleSubscribe = () => {
    setShowPopup(true);
  };

  return (
    <Box
      position={"relative"}
      top={0}
      bottom={0}
      left={0}
      right={0}
      justifyContent={"center"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        background: theme.palette.background.mediumBackgroundGradient,
      }}
      overflow={"hidden"}
    >
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        maxWidth={"1200px"}
        paddingLeft={"24px"}
        paddingRight={"24px"}
        overflow={"hidden"}
      >
        <Spacer height={"60px"} />
        <Header2
          text={"Subscribe to Our Newsletter"}
          color={theme.palette.colors.grayScale.white}
        />
        <Spacer height={"12px"} />
        <Subtitle
          text={`Stay updated with the latest news, updates, and exclusive insights from the Multisynq Foundation.`}
          color={theme.palette.colors.grayScale.white}
          sx={{ maxWidth: "500px" }}
        />
        <Spacer height={"50px"} />
        <NewsletterInputField
          onEmailChange={handleEmailChange}
          onSubscribe={handleSubscribe}
        />
        <Spacer height={"70px"} />
      </Box>
      <NewsletterPopup
        triggerPopup={showPopup}
        onDismiss={() => setShowPopup(false)}
        initialEmail={email}
      />
    </Box>
  );
};

export default LandingNewsletterSection;
