import React from "react";
import { Box, Typography } from "@mui/material";
import imageSrc from "../../images/plane.png";
import { Header2 } from "../ui-elements/typography/Header2";
import { Body } from "../ui-elements/typography/Body";

interface FormSubmissionFeedbackProps {
  title: string;
  message: string;
  center?: boolean;
}

const FormSubmissionFeedback: React.FC<FormSubmissionFeedbackProps> = ({
  title,
  message,
  center = false,
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"400px"}
      sx={{
        padding: "30px",
        maxWidth: "600px",
        margin: "auto",
        textAlign: "center",
        animation: "fadeIn 0.15s ease-in-out",
        "@keyframes fadeIn": {
          "0%": {
            opacity: 0,
            transform: "scale(0.9)",
          },
          "100%": {
            opacity: 1,
            transform: "scale(1)",
          },
        },
      }}
    >
      <Box flex={1} />
      <Box
        component="img"
        position={"absolute"}
        src={imageSrc}
        alt="Submission Feedback"
        sx={{
          width: "100%",
          maxWidth: "250px",
          height: "auto",
          marginBottom: "90px",
          marginLeft: center ? "0px" : "300px",
        }}
      />
      <Header2 text={title} />
      <Body text={message} />
      <Box flex={1} />
    </Box>
  );
};

export default FormSubmissionFeedback;
