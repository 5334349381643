import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const initialState = {
  confirmationPopup: {
    show: false,
    confirmationHandler: "" as string,
    title: "" as string,
    message: "" as string,
    confirmationText: "" as string,
    isDestructive: false,
    payload: "" as string | null,
  },
  onConfirm: {
    command: "",
    payload: "",
  },
  onCancel: {
    command: "",
    payload: "",
  },
  error: "" as unknown,
};

export const confirmPopup = createAsyncThunk(
  "confirmationPopup/confirmPopup",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const confirmationPopup = state.confirmationPopup.confirmationPopup;
    const confirmHandler = confirmationPopup.confirmationHandler;
    const payload = confirmationPopup.payload;
    dispatch(setOnConfirm({ command: confirmHandler, payload: payload ?? "" }));
    dispatch(hideConfirmPopup());
  }
);

export const cancelPopup = createAsyncThunk(
  "confirmationPopup/cancelPopup",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const confirmationPopup = state.confirmationPopup.confirmationPopup;
    const confirmHandler = confirmationPopup.confirmationHandler;
    const payload = confirmationPopup.payload;
    dispatch(setOnCancel({ command: confirmHandler, payload: payload ?? "" }));
  }
);

export const confirmationPopupSlice = createSlice({
  name: "confirmationPopup",
  initialState: initialState,
  reducers: {
    setOnConfirm: (state, action) => {
      state.onConfirm.command = action.payload.command;
      state.onConfirm.payload = action.payload.payload;
    },
    setOnCancel: (state, action) => {
      state.onCancel.command = action.payload.command;
      state.onCancel.payload = action.payload.payload;
    },
    showConfirmPopup: (state, action) => {
      state.confirmationPopup.show = true;
      state.confirmationPopup.confirmationHandler =
        action.payload.confirmationHandler ?? "";
      state.confirmationPopup.title = action.payload.title ?? "";
      state.confirmationPopup.message = action.payload.message ?? "";
      state.confirmationPopup.confirmationText =
        action.payload.confirmationText ?? "";
      state.confirmationPopup.isDestructive =
        action.payload.isDestructive ?? false;
      state.confirmationPopup.payload = action.payload.payload ?? "";
    },
    hideConfirmPopup: (state) => {
      state.confirmationPopup.show = false;
      state.confirmationPopup.confirmationHandler = "";
    },
  },
});

export const { showConfirmPopup, hideConfirmPopup, setOnConfirm, setOnCancel } =
  confirmationPopupSlice.actions;

export default confirmationPopupSlice.reducer;
